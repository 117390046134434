import { CanvasDrawer } from "../utils/CanvasDrawer";
import { Config, LandmarkPoint, Coordinate, PointAndCoordinate } from "../index.interface";
import Calc from "../utils/Calc";

export class DrawingFacialLandmarks extends CanvasDrawer {
  private landmarkPoints: number[] = Array(468)
    .fill(0)
    .map((_, index) => index);

  faceCoordinates: PointAndCoordinate;
  sideFaceCoordinate: PointAndCoordinate;

  constructor({ imageEle, canvasEle, normalizedCoordinate }: Config) {
    super(imageEle, canvasEle);
    this.faceCoordinates = Calc.getDenormalizedCoordinates(imageEle, normalizedCoordinate, this.landmarkPoints as LandmarkPoint[]);
    this.sideFaceCoordinate = Calc.getSideFaceSideCoordinate(imageEle, this.faceCoordinates);
    this.sideFaceCoordinate = this.adjustSideFaceCoordinates(this.sideFaceCoordinate);
  }

  drawLandmarkPoints = () => {
    this.landmarkPoints.forEach((keyPoint) => {
      this.drawPoint(this.faceCoordinates[keyPoint], { color: "rgba(255, 255, 255, 0.40)" });
    });
  };

  drawSideFace = () => {
    const noseLinekeyPoints = [
      10, 151, 9, 8, 168, 6, 197, 195, 5, 4, 1, 19, 94, 2, 164, 0, 11, 12, 13, 14, 15, 16, 17, 18, 200, 199, 175, 152,
    ];
    const noseLineCoordinates: Coordinate[] = [];

    noseLinekeyPoints.forEach((keyPoint) => {
      noseLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
    });

    this.drawLineByCoordinates(noseLineCoordinates, {
      lineLength: 100,
      lineColor: "rgba(183, 255, 244, 0.80)",
      lineType: "SOLID",
      lineWidth: 1,
      dropShadow: "drop-shadow(-4.44px 0px 8.881px #8FF) drop-shadow(-1.776px 0px 3.552px #8DFF98)",
    });

    setTimeout(() => {
      for (const keyPoint of this.landmarkPoints) {
        if (keyPoint < 248) {
          if (
            [
              10, 151, 9, 8, 156, 168, 35, 112, 6, 233, 128, 111, 121, 197, 114, 120, 117, 119, 118, 198, 5, 4, 115, 48, 218, 219, 64, 79,
              235, 79, 1, 20, 242, 141, 19, 94, 2, 164, 167, 0, 11, 12, 95, 178, 87, 88, 14, 15, 16, 17, 83, 18, 201, 200, 208, 199, 171,
              175, 248, 152, 142,
            ].includes(keyPoint)
          ) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#0FC8FA" });
          } else if ([72, 73, 184, 74, 76, 77, 90, 180, 85, 47, 100, 126, 36].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#0C74BB" });
          } else if (
            [223, 224, , 222, 225, 221, 113, 189, 244, 245, 188, 174, 236, 134, 220, 237, 239, 238, 241, 125, 143, 165, 92].includes(
              keyPoint,
            )
          ) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#38DCFB" });
          } else if (
            [
              55, 27, 28, 29, 30, 56, 247, 193, 190, 243, 25, 110, 22, 122, 24, 23, 196, 51, 3, 51, 45, 44, 37, 39, 40, 185, 61, 146, 91,
              181, 84,
            ].includes(keyPoint)
          ) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#6FD0D0" });
          } else if (
            [
              66, 105, 107, 63, 52, 65, 53, 70, 46, 124, 226, 26, 31, 232, 228, 231, 229, 230, 209, 131, 49, 102, 129, 227, 116, 137, 123,
              50, 147, 177, 187, 207, 213, 215, 192, 212, 214,
            ].includes(keyPoint)
          ) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#DDFFFF" });
          } else if ([205, 166, 59, 75, 203, 240, 206, 216, 186, 57, 43, 202].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#002D7B" });
          } else if ([60, 99, 97, 38, 41, 183, 42, 81, 191, 80, 82, 13, 62, 78, 96, 89, 179, 86].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#002080" });
          } else if (
            [109, 67, 103, 69, 108, 54, 104, 68, 21, 71, 139, 34, 143, 138, 135, 210, 136, 129, 150, 149, 176].includes(keyPoint)
          ) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#7D7D7D" });
          } else if ([159, 160, 1611, 158, 246, 157, 33, 130, 7, 173, 133, 163, 155, 144, 154, 145, 153].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#5F57FF" });
          } else if ([205, 203, 206, 216, 186, 106, 182, 204, 211, 194, 170, 32, 140].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#9CFFFF" });
          } else if ([98].includes(keyPoint)) {
            this.drawPoint(this.sideFaceCoordinate[keyPoint], { color: "#052481" });
          }
        } else {
          break;
        }
      }
    }, 300);
  };

  adjustByDegree = (coordinates: PointAndCoordinate, angle: number, degree: number): PointAndCoordinate => {
    const radians = ((angle - degree) * Math.PI) / 180;

    const adjustedCoordinates: PointAndCoordinate = {};
    Object.keys(coordinates).forEach((key) => {
      const point = coordinates[parseInt(key)];
      const adjustedX =
        Math.cos(radians) * (point.x - coordinates[6].x) - Math.sin(radians) * (point.y - coordinates[6].y) + coordinates[6].x;
      const adjustedY =
        Math.sin(radians) * (point.x - coordinates[6].x) + Math.cos(radians) * (point.y - coordinates[6].y) + coordinates[6].y;

      adjustedCoordinates[parseInt(key)] = {
        x: this.adjustNoseHeight(adjustedX, parseInt(key)),
        y: adjustedY - 6.5,
        z: point.z,
      };
    });

    return adjustedCoordinates;
  };

  private adjustSideFaceCoordinates = (sideFaceCoordinates: PointAndCoordinate) => {
    let angle = 0;
    const adjustAngle = 90;
    const baseVerticalLinePoint = {
      left: { ...sideFaceCoordinates[6], x: sideFaceCoordinates[6].x - 150 },
      right: { ...sideFaceCoordinates[6], x: sideFaceCoordinates[6].x + 150 },
    };

    const intersectionPoint = Calc.findIntersectionPoint({
      lineA: {
        pointA: baseVerticalLinePoint.left,
        pointB: baseVerticalLinePoint.right,
      },
      lineB: {
        pointA: sideFaceCoordinates[10],
        pointB: sideFaceCoordinates[152],
      },
    });

    if (intersectionPoint) {
      angle = Calc.calculateAngleWithVectors({
        pointA: intersectionPoint,
        pointB: sideFaceCoordinates[152],
      });
    }

    if (angle !== adjustAngle) {
      sideFaceCoordinates = this.adjustByDegree(sideFaceCoordinates, adjustAngle, angle);
    }

    return sideFaceCoordinates;
  };

  private adjustNoseHeight = (x: number, key: number) => {
    let resultX = x;
    if (key === 9) {
      resultX -= 2;
    }

    if (key === 8 || key === 168) {
      resultX -= 2;
    }

    if (key === 6 || key === 197 || key === 4) {
      resultX -= 3;
    }

    if (key === 195) {
      resultX -= 4;
    }

    if (key === 5) {
      resultX -= 8;
    }

    if (key === 1) {
      resultX -= 2;
    }

    if (key === 19) {
      resultX -= 1;
    }

    if (key == 129) {
      resultX += 2;
    }

    if (key == 2) {
      resultX -= 8;
    }

    return resultX;
  };

  private adjustNoseHeightY = (y: number, key: number) => {
    let resultY = y;
    // if (key === 9) {
    //   resultX -= 2;
    // }

    // if (key === 8 || key === 168) {
    //   resultX -= 2;
    // }

    // if (key === 6 || key === 197 || key === 4) {
    //   resultX -= 3;
    // }

    // if (key === 195) {
    //   resultX -= 4;
    // }

    // if (key === 5) {
    //   resultX -= 8;
    // }

    // if (key === 1) {
    //   resultX -= 2;
    // }

    // if (key === 19) {
    //   resultX -= 1;
    // }

    // if (key == 129) {
    //   resultX += 2;
    // }

    return resultY;
  };
}
