import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { injectable } from "inversify";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { PaymentSubscriptionCheckoutViewModel } from "./model/PaymentSubscriptionCheckoutViewModel";
import { PaymentDetail } from "@view-data/Payment";

@injectable()
export default class PaymentSubscriptionCheckoutViewModelImpl implements PaymentSubscriptionCheckoutViewModel {
  private clientKey = "test_ck_Gv6LjeKD8azjADMWDvQw3wYxAdXy";

  data: PaymentSubscriptionCheckoutViewModel["data"] = {};
  input: PaymentSubscriptionCheckoutViewModel["input"] = {};
  output: PaymentSubscriptionCheckoutViewModel["output"] = {
    orderData: new BehaviorSubject<PaymentDetail | null>(null),
  };
  route: PaymentSubscriptionCheckoutViewModel["route"] = {};

  constructor(orderId: string, readonly uiSystem: UISystemManager) {}
}
