import React from "react";
import styled from "styled-components";
import ComponentButton from "./ComponentButton";
import { Typo } from "..";
import Icon from "@icon/index";

interface CheckBoxProps {
  label: string;
  isSelected: boolean;
  labelStyle?: {
    bold?: boolean;
    color?: string;
  };
  selectedColor?: string;
  disabled?: boolean;
  click: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ label, isSelected, selectedColor, labelStyle, disabled, click }) => {
  return (
    <ComponentButton
      component={
        <CheckBoxLayout>
          <Box
            $isSelected={isSelected}
            $selectedColor={selectedColor}
          >
            {isSelected ? (
              <Icon.OutlinedCheck
                color={"neutralWhiteDefault"}
                _16px
              />
            ) : null}
          </Box>
          <Typo.B2
            value={label}
            color={labelStyle?.color ??"#000"}
            bold={labelStyle?.bold !== undefined ? labelStyle.bold : true}
          />
        </CheckBoxLayout>
      }
      disabled={disabled}
      sx={{ height: "fit-content" }}
      onClick={click}
    />
  );
};

export default CheckBox;

const CheckBoxLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Box = styled.div<{ $isSelected: boolean; $selectedColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid #eceef2;
  border-radius: 4px;
  background-color: ${({ $isSelected, $selectedColor }) => ($isSelected ? $selectedColor ?? "#000" : "#fff")};
`;
