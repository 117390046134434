import { injectable } from "inversify";
import { Subject } from "rxjs";
import type { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import type { ReqeustFaceLandmark } from "@/domain/usecase/consulting/model/RequestFaceLandmark";
import { FaceFit, FaceLandmark, Consulting } from "@/application/view-data";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";
import { FaceScanRight45ViewModel } from "./model/FaceScanRight45ViewModel";
import * as RightNose45 from "./face-classification/nose/right-nose-45";

@injectable()
export class FaceScanRight45ViewModelImpl implements FaceScanRight45ViewModel {
  data: FaceScanRight45ViewModel["data"] = {
    landmarks: null,
    faceScanResult: null,
  };
  output: FaceScanRight45ViewModel["output"] = {
    landmarks: new Subject<FaceLandmark.Coordinate[][]>(),
  };

  constructor(private readonly ucGetFaceLandmarksByGCS: GetFaceLandmarks) {}

  event: FaceScanRight45ViewModel["event"] = {
    onInit: (faceScanResult) => {
      const sub = this.ucGetFaceLandmarksByGCS.execute({ url: faceScanResult.faceLandmarksJSONURL }).subscribe({
        next: ({ landmarks }) => {
          this.data.faceScanResult = faceScanResult;
          this.data.landmarks = landmarks;
          this.output.landmarks.next(this.data.landmarks);
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },

    face: {
      onSetFaceLandmark: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawLandmarkPoints();
        }
      },
    },
    nose: {
      onScanNoseLength: (canvasConfig, currentNoseLengthRatio) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.NoseLengthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });

          const bestScan = new RightNose45.NoseLengthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          currentScan.drawPhotoBase(currentNoseLengthRatio);
          bestScan.drawBestRatio();
        }
      },
      onScanNoseTipHeight: (canvasConfig, currentNoseTipHeightRatio) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.NoseTipHeightRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.NoseTipHeightRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          currentScan.drawPhotoBase(currentNoseTipHeightRatio);
          bestScan.drawBestRatio();
        }
      },

      onScanForeheadNoseAngle45: (canvasConfig, currentForeheadNoseAngle) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.ForeheadNoseAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.ForeheadNoseAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          currentScan.drawPhotoBase(currentForeheadNoseAngle);
          bestScan.drawBestRatio();
        }
      },

      onScanNasolabialAngle45: (canvasConfig, currentNasolabialAngle) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.NasolabialAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.NasolabialAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          if (currentNasolabialAngle?.angle) {
            currentScan.drawPhotoBase(currentNasolabialAngle?.angle);
          }
          bestScan.drawBestRatio();
        }
      },

      onScanNoseTipShape45: (canvasConfig, currentNoseTipRatio) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.NoseTipShape45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.NoseTipShape45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          currentScan.drawPhotoBase(currentNoseTipRatio);
          bestScan.drawBestRatio();
        }
      },

      onScanNoseBridgeWidthRatio45: (canvasConfig, currentNoseBridgeWidthRatio) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.NoseBridgeWidthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.NoseBridgeWidthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          if (currentNoseBridgeWidthRatio) {
            currentScan.drawPhotoBase(currentNoseBridgeWidthRatio);
          }
          bestScan.drawBestRatio();
        }
      },

      onScanColumellarLobularAngle45: (canvasConfig, angle) => {
        if (this.data.landmarks) {
          const currentScan = new RightNose45.ColumellarLobularAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new RightNose45.ColumellarLobularAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          currentScan.drawPhotoBase(angle);
          bestScan.drawBestRatio();
        }
      },
    },
  };
}
