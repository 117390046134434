import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../../index.interface";
import Calc from "../../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "@view-model/face-classification/utils/Styles";

export class NoseTipShape45 extends DrawingFacialLandmarks {
  private coordinate: {
    noseRight: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
    noseLeft: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
  };

  ratio: {
    right: number;
    center: number;
    left: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async (
    ratio: {
      right: number;
      center: number;
      left: number;
    } | null,
  ) => {
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(500);
    this.photoBaseDrawer.drawRatioText(ratio);
    await this.delay(300);
    this.photoBaseDrawer.drawGuideLine();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(500);
    this.bestRatioDrawer.drawRatioText({ right: 2.0, center: 6.0, left: 2.0 });
    await this.delay(300);
    this.photoBaseDrawer.drawGuideLine();
  };

  private photoBaseDrawer = {
    drawNoseLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.right.top,
        point2: this.coordinate.noseRight.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.left.top,
        point2: this.coordinate.noseRight.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.right.top,
        point2: this.coordinate.noseLeft.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },

    drawRatioText: (
      ratio: {
        right: number;
        center: number;
        left: number;
      } | null,
    ) => {
      const textStyle = { x: 0, y: 25, fontSize: 18, color: "#FF9900" };
      const rightRatio = ratio?.right ?? this.ratio.right;
      const centerRatio = ratio?.center ?? this.ratio.center;
      const leftRatio = ratio?.left ?? this.ratio.left;

      this.drawTextBetweenPoints(
        `${Calc.pFloat(rightRatio * 10)}`,
        this.coordinate.noseRight.right.bottom,
        this.coordinate.noseRight.left.bottom,
        textStyle,
      );
      this.drawTextBetweenPoints(
        `${Calc.pFloat(centerRatio * 10)}`,
        this.coordinate.noseRight.left.bottom,
        this.coordinate.noseLeft.right.bottom,
        textStyle,
      );
      this.drawTextBetweenPoints(
        `${Calc.pFloat(leftRatio * 10)}`,
        this.coordinate.noseLeft.right.bottom,
        this.coordinate.noseLeft.left.bottom,
        { ...textStyle, x: 35 },
      );
    },

    drawGuideLine: () => {
      const yPoint = this.coordinate.noseLeft.left.bottom.y - 10;
      const right = { ...this.coordinate.noseRight.right.bottom, y: yPoint };
      const center = { ...this.coordinate.noseRight.left.bottom, y: yPoint };
      const left = { ...this.coordinate.noseLeft.right.bottom, y: yPoint };

      this.drawLineBetweenPoints({ point1: right, point2: center, lineStyle: DotLineStyle, isDrawEndPoint: false });
      this.drawLineBetweenPoints({ point1: center, point2: left, lineStyle: DotLineStyle, isDrawEndPoint: false });
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.right.top,
        point2: this.coordinate.noseRight.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.left.top,
        point2: this.coordinate.noseRight.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.right.top,
        point2: this.coordinate.noseLeft.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      await this.delay(300);

      const keyPoints = [219, 218, 237, 125];
      const lineCoordinates: Coordinate[] = [];
      const sideLineCoordinates: Coordinate[] = [];

      keyPoints.forEach((keyPoint) => {
        lineCoordinates.push(this.faceCoordinates[keyPoint]);
        if (keyPoint < 248) {
          sideLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
        }
      });
      this.drawLineByCoordinates(lineCoordinates, { ...PhotoBaseLine, lineColor: "#FF678C" }, true);
    },
    drawGuideLine: () => {
      const yPoint = this.coordinate.noseLeft.left.bottom.y - 10;
      const right = { ...this.coordinate.noseRight.right.bottom, y: yPoint };
      const center = { ...this.coordinate.noseRight.left.bottom, y: yPoint };
      const left = { ...this.coordinate.noseLeft.right.bottom, y: yPoint };

      this.drawLineBetweenPoints({ point1: right, point2: center, lineStyle: DotLineStyle, isDrawEndPoint: false });
      this.drawLineBetweenPoints({ point1: center, point2: left, lineStyle: DotLineStyle, isDrawEndPoint: false });
    },

    drawRatioText: (
      ratio: {
        right: number;
        center: number;
        left: number;
      } | null,
    ) => {
      const textStyle = { x: 0, y: 50, fontSize: 18, color: "#00D7CA" };
      const rightRatio = ratio?.right ?? this.ratio.right;
      const centerRatio = ratio?.center ?? this.ratio.center;
      const leftRatio = ratio?.left ?? this.ratio.left;

      this.drawTextBetweenPoints(`2.0`, this.coordinate.noseRight.right.bottom, this.coordinate.noseRight.left.bottom, textStyle);
      this.drawTextBetweenPoints(`6.0`, this.coordinate.noseRight.left.bottom, this.coordinate.noseLeft.right.bottom, textStyle);
      this.drawTextBetweenPoints(`2.0`, this.coordinate.noseLeft.right.bottom, this.coordinate.noseLeft.left.bottom, {
        ...textStyle,
        x: 35,
      });
    },
  };

  private getCoordinate = () => {
    const topYPoint = this.faceCoordinates[197].y;
    const middleYPoint = this.faceCoordinates[4].y;
    const bottomYPoint = this.faceCoordinates[2].y + 25;
    return {
      noseRight: {
        right: {
          top: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: topYPoint },
          bottom: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: bottomYPoint },
        },
        left: {
          top: { ...this.faceCoordinates[220], y: topYPoint },
          bottom: { ...this.faceCoordinates[220], y: bottomYPoint },
        },
      },
      noseLeft: {
        right: {
          top: { ...this.faceCoordinates[440], y: topYPoint },
          bottom: { ...this.faceCoordinates[440], y: bottomYPoint },
        },
        left: {
          top: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: topYPoint },
          bottom: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: bottomYPoint },
        },
      },
    };
  };

  private calcRatio = () => {
    const rightDistance = Calc.calculateDistance(this.coordinate.noseRight.right.bottom, this.coordinate.noseRight.left.bottom, "2d");
    const centerDistance = Calc.calculateDistance(this.coordinate.noseRight.left.bottom, this.coordinate.noseLeft.right.bottom, "2d");
    const leftDistance = Calc.calculateDistance(this.coordinate.noseLeft.right.bottom, this.coordinate.noseLeft.left.bottom, "2d");

    const totalDistance = rightDistance + centerDistance + leftDistance;

    return {
      right: Calc.calcRatio(totalDistance, rightDistance),
      center: Calc.calcRatio(totalDistance, centerDistance),
      left: Calc.calcRatio(totalDistance, leftDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
