import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { injectable } from "inversify";
import { PaymentSubscriptionResultViewModel } from "./model/PaymentSubscriptionResultViewModel";

@injectable()
export default class PaymentSubscriptionResultViewModelImpl implements PaymentSubscriptionResultViewModel {
  data: PaymentSubscriptionResultViewModel["data"] = {};
  input: PaymentSubscriptionResultViewModel["input"] = {};
  output: PaymentSubscriptionResultViewModel["output"] = {};
  route: PaymentSubscriptionResultViewModel["route"] = {};

  constructor(readonly uiSystem: UISystemManager) {
    this.init();
  }

  private init = () => {
  };
}
