import { ContainerModule, interfaces } from "inversify";
import { SlackWebHook } from "./model/SlackWebHook";
import { WEBHOOK_TYPES } from "./index.container.types";
import SlackWebHookImpl from "./SlackWebHookImpl";

const WebHooks = new ContainerModule((bind: interfaces.Bind) => {
  bind<SlackWebHook>(WEBHOOK_TYPES.SlackWebHook).to(SlackWebHookImpl);
});

export default WebHooks;
