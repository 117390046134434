import type { UISystemManager } from "../ui-system/view-model/model";
import type { Login } from "@/domain/usecase/auth/model/Login";
import type { GetUserV2 } from "@/domain/usecase/auth/model/GetUserV2";
import logger from "@/logger";
import { DeprecatedUserInfo, UserInfo } from "@view-data/User";
import { MainViewModel } from "@view-model/model/MainViewModel";
import { injectable } from "inversify";
import { BehaviorSubject, Subject } from "rxjs";
import { PRODUCTS, Product } from "@view-data/Product";

@injectable()
export class MainViewModelImpl implements MainViewModel {
  uiSystem: UISystemManager;
  input: {
    authorizeByPathname: (pathname: string) => void;
    authorizeByCode: (code: string, redirectUri: string) => void;
  };
  output: {
    deprecatedUserInfo: Subject<DeprecatedUserInfo>;
    userInfo: Subject<UserInfo>;
    accessibleProducts: BehaviorSubject<Product[]>;
  };
  route: { toHome: Subject<void>; toLogin: Subject<void> };

  constructor(private readonly uiSystemManager: UISystemManager, private readonly ucLogin: Login, private readonly ucGetUserV2: GetUserV2) {
    this.uiSystem = this.uiSystemManager;

    this.output = {
      deprecatedUserInfo: new Subject(),
      userInfo: new Subject(),
      accessibleProducts: new BehaviorSubject<Product[]>([]),
    };

    this.route = {
      toHome: new Subject(),
      toLogin: new Subject(),
    };

    this.input = {
      authorizeByPathname: (pathname: string) => {
        const isPrivatePage = !pathname.includes("/login") && !pathname.includes("/payment");

        if (isPrivatePage) {
          const sub = this.ucGetUserV2.execute().subscribe({
            next: ({ user, contract, hospital }) => {
              if (pathname === "/") {
                this.route.toHome.next();
              }

              const accessibleProducts = PRODUCTS.filter((product) =>
                contract?.accessibleProducts.some((p) => p.name === product.name),
              ).map((product) => {
                const subMenus = [...product.subMenus];
                const accessibleFeatures = contract?.accessibleProducts
                  .find((p) => p.name === product.name)
                  ?.features.map(({ name }) => name);
                return {
                  ...product,
                  subMenus: accessibleFeatures ? subMenus.filter((sb) => accessibleFeatures.includes(sb.name)) : [],
                };
              }) as Product[];

              this.output.userInfo.next({
                id: user.id,
                name: user.name,
                role: user.role,
                hospital: {
                  id: hospital.id,
                  name: hospital.name,
                  profile: hospital.logoImageUrl,
                },
              });

              this.output.accessibleProducts.next([...accessibleProducts]);
              sub.unsubscribe();
            },
            error: (error) => {
              this.route.toLogin.next();
              sub.unsubscribe();
            },
          });
        }
      },
      authorizeByCode: (code, redirectUri) => {
        this.ucLogin
          .execute({
            code,
            redirectUri,
          })
          .subscribe({
            next: () => {
              this.route.toHome.next();
            },
            error: (error) => {
              this.route.toLogin.next();
              logger.error(error);
            },
          });
      },
    };
  }
}
