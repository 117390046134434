import Calc from "../utils/Calc";
import { CanvasDrawer } from "../utils/CanvasDrawer";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class NoseLengthRatio extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    nose: {
      line: { top: Coordinate; bottom: Coordinate };
      guideLine: { top: Coordinate; bottom: Coordinate };
    };
    jaw: {
      guideLine: { top: Coordinate; bottom: Coordinate };
    };
  };
  ratio: {
    noseRatio: number;
    jawRatio: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    // this.photoBaseDrawer.drawGuideLines();
    // await this.delay(300);
    this.photoBaseDrawer.drawFrontalFaceLines();
    await this.delay(300);
    // this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawFrontalFaceLines();
    // this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    // this.bestRatioDrawer.drawGuideLines();
    // await this.delay(600);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },

    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.line.top,
        point2: this.coordinate.nose.line.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },

    drawGuideLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.top,
        point2: this.coordinate.nose.guideLine.bottom,
        lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.top,
        point2: this.coordinate.jaw.guideLine.bottom,
        lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
        isDrawEndPoint: true,
      });
      await this.delay(300);
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.top,
        point2: this.sideFaceCoordinate[168],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.bottom,
        point2: this.sideFaceCoordinate[4],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.top,
        point2: this.sideFaceCoordinate[13],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.bottom,
        point2: this.sideFaceCoordinate[152],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },

    drawRatioText: () => {
      const textStyle = { x: 40, y: 0, fontSize: 18, color: "#FF9900" };

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${this.ratio.noseRatio}`,
        this.faceCoordinates[168],
        this.faceCoordinates[4],
        textStyle,
      );

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${this.ratio.jawRatio}`,
        this.faceCoordinates[152],
        this.faceCoordinates[13],
        textStyle,
      );

      // this.drawTextBetweenPoints(
      //   `${this.ratio.noseRatio}`,
      //   this.coordinate.nose.guideLine.top,
      //   this.coordinate.nose.guideLine.bottom,
      //   textStyle,
      // );

      // this.drawTextBetweenPoints(
      //   `${this.ratio.jawRatio}`,
      //   this.coordinate.jaw.guideLine.top,
      //   this.coordinate.jaw.guideLine.bottom,
      //   textStyle,
      // );
    },
  };

  private bestRatioDrawer = {
    drawFrontalFaceLines: () => {
      const noseLengthDistance = Calc.calculateDistance(this.coordinate.nose.line.top, this.coordinate.nose.line.bottom, "2d");

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: { ...this.faceCoordinates[13], y: this.faceCoordinates[13].y + noseLengthDistance },
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      // const noseLengthDistance = Calc.calculateDistance(this.coordinate.nose.line.top, this.coordinate.nose.line.bottom, "2d");
      // this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
      //   point1: this.faceCoordinates[168],
      //   point2: this.faceCoordinates[4],
      //   lineStyle: BestRatioLineStyle,
      //   isDrawEndPoint: true,
      // });

      // this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
      //   point1: this.faceCoordinates[13],
      //   point2: { ...this.faceCoordinates[13], y: this.faceCoordinates[13].y + noseLengthDistance },
      //   lineStyle: BestRatioLineStyle,
      //   isDrawEndPoint: true,
      // });
    },

    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.line.top,
        point2: this.coordinate.nose.line.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },

    drawGuideLines: async () => {
      const noseLengthDistance = Calc.calculateDistance(this.coordinate.nose.line.top, this.coordinate.nose.line.bottom, "2d");

      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.top,
        point2: this.coordinate.nose.guideLine.bottom,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.top,
        point2: this.coordinate.jaw.guideLine.bottom,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.top,
        point2: this.coordinate.jaw.guideLine.bottom,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });
      await this.delay(300);
      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.top,
        point2: this.sideFaceCoordinate[13],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.jaw.guideLine.bottom,
        point2: this.sideFaceCoordinate[152],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.top,
        point2: this.sideFaceCoordinate[168],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.nose.guideLine.bottom,
        point2: this.sideFaceCoordinate[4],
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },

    drawRatioText: () => {
      const textStyle = { x: 40, y: 25, fontSize: 18, color: "#00D7CA" };
      const noseLengthDistance = Calc.calculateDistance(this.coordinate.nose.line.top, this.coordinate.nose.line.bottom, "2d");

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${this.ratio.noseRatio}`,
        this.faceCoordinates[168],
        this.faceCoordinates[4],
        textStyle,
      );

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(`${this.ratio.noseRatio}`, this.faceCoordinates[152], this.faceCoordinates[13], {
        ...textStyle,
        x: 30,
      });

      //옆모습으로 그리는 것
      // this.drawTextBetweenPoints(
      //   `${this.ratio.noseRatio}`,
      //   this.coordinate.nose.guideLine.top,
      //   this.coordinate.nose.guideLine.bottom,
      //   textStyle,
      // );

      // this.drawTextBetweenPoints(
      //   `${this.ratio.noseRatio}`,
      //   this.coordinate.jaw.guideLine.top,
      //   { ...this.coordinate.jaw.guideLine.top, y: this.coordinate.jaw.guideLine.top.y + noseLengthDistance },
      //   { ...textStyle, y: -30 },
      // );
    },
  };
  private getCoordinate = (): {
    nose: {
      line: { top: Coordinate; bottom: Coordinate };
      guideLine: { top: Coordinate; bottom: Coordinate };
    };
    jaw: {
      guideLine: { top: Coordinate; bottom: Coordinate };
    };
  } => {
    const offsetX = 30;
    const coordinate168 = this.sideFaceCoordinate[168];
    const coordinate4 = this.sideFaceCoordinate[4];

    return {
      nose: {
        line: {
          top: { ...coordinate168, x: coordinate168.x + offsetX },
          bottom: { ...coordinate4, x: coordinate4.x + offsetX },
        },
        guideLine: {
          top: { ...coordinate168, x: coordinate4.x + offsetX },
          bottom: { ...coordinate4, x: coordinate4.x + offsetX },
        },
      },
      jaw: {
        guideLine: {
          top: { ...this.sideFaceCoordinate[13], x: coordinate4.x + offsetX },
          bottom: { ...this.sideFaceCoordinate[152], x: coordinate4.x + offsetX },
        },
      },
    };
  };

  private calcRatio = (): {
    noseRatio: number;
    jawRatio: number;
  } => {
    const noseLengthDistance = Calc.calculateDistance(this.coordinate.nose.line.top, this.coordinate.nose.line.bottom, "2d");
    const jawLengthDistance = Calc.calculateDistance(this.coordinate.jaw.guideLine.top, this.coordinate.jaw.guideLine.bottom, "2d");

    return {
      noseRatio: Calc.calcRatio(noseLengthDistance, noseLengthDistance),
      jawRatio: Calc.calcRatio(noseLengthDistance, jawLengthDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
