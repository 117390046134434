import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Widgets from "@/application/view/widget";
import Icon from "@icon/index";
import colors from "@view/styles/colors";
import { BasicMenuProps } from "@view/widget/dropdown/BasicMenu";

interface BasicSelectProps {
  selectedItem: string | null;
  selectButton?: React.ReactNode;
  placeholder: string;
  disabled?: boolean;
  items: BasicMenuProps["items"];
  fill?: boolean;
  getOpenStatus?: (open: boolean) => void;
}

const BasicSelect: React.FC<BasicSelectProps> = ({ selectedItem, placeholder, selectButton, fill, disabled, items, getOpenStatus }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getOpenStatus && getOpenStatus(isOpen);
  }, [isOpen]);

  return (
    <BasicSelectLayout $fill={fill}>
      <Widgets.DropDown.BasicMenu
        button={
          selectButton ? (
            selectButton
          ) : (
            <SelectBox>
              <ButtonLabel>{selectedItem ?? placeholder}</ButtonLabel>
              {items.length > 0 ? (
                <IconLayout $isOpen={isOpen}>
                  <Icon.ArrowOpen color={"neutralGray600"} />
                </IconLayout>
              ) : null}
            </SelectBox>
          )
        }
        items={items}
        disabled={disabled}
        setIsOpen={setIsOpen}
      />
    </BasicSelectLayout>
  );
};

export default BasicSelect;

const BasicSelectLayout = styled.div<{ $fill?: boolean }>`
  width: ${({ $fill }) => ($fill ? "100%" : "fit-content")};
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  /* width: 100%; */

  border-radius: 6px;
  border: 1px solid #eceef2;
  background-color: ${colors.drwo_bg_100};
  min-width: 130px;
  height: 36px;
  border-radius: 6px;
`;

const ButtonLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ababab;
`;

const IconLayout = styled.div<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? 180 : 0)}deg);
  transition: 0.3s;
`;
