import { LocalStorage } from "@/data/interactor/repository/LocalRepositoryImpl";
import { SessionStorage } from "@/data/interactor/repository/SessionRepositoryImpl";
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosResponse,
  CustomParamsSerializer,
  RawAxiosRequestHeaders,
  CancelTokenSource as AxiosCancelTokenSource,
  CancelToken as AxiosCancelToken,
} from "axios";
import qs from "qs";
import { Observable, catchError, from, map, throwError } from "rxjs";

export const ApiCancelToken = axios.CancelToken;
export type CancelTokenSource = AxiosCancelTokenSource;

export class CaremindClientException {
  code: string;
  message: string;

  constructor(code: string, message: string) {
    this.code = code;
    this.message = message;
  }
}

// export const BASE_URL: any = process.env.API_URL;
export const BASE_URL: any = "http://10.190.240.200:8081"
export class API {
  private static IS_LOCKED: boolean = false;
  private static BASE_URL: string = BASE_URL;
  private static CLIENT_ID = process.env.CLIENT_ID ?? "d6653243-49e3-49b8-9cf3-380a3d4d0501";
  private static isExpiredToken = (response?: AxiosResponse<CaremindException, any>) => {
    if (response?.status === 401 && (response?.data.error_code === "010202" || response?.data.error_code === "010005")) {
      return true;
    }
    return false;
  };
  private static refreshToken = async (refreshToken: string): Promise<string> => {
    const res = await axios.post(
      `${this.BASE_URL}/auth/refresh?client_id=${this.CLIENT_ID}&grant_type=refresh_token&refresh_token=${refreshToken}`,
    );
    return res.data.session_token;
  };

  private static createInstanceBy = (credential: boolean): AxiosInstance => {
    const instance = axios.create({
      baseURL: this.BASE_URL,
    });

    if (!credential) return instance;

    instance.interceptors.request.use(async (request) => {
      const sessionToken = SessionStorage.getItem("sessionToken");

      if (sessionToken) {
        request.headers.Authorization = `Bearer ${SessionStorage.getItem("sessionToken")}`;

        return request;
      }

      const refreshToken = LocalStorage.getItem("refreshToken");

      if (refreshToken) {
        if (!API.IS_LOCKED) {
          this.IS_LOCKED = true;
          const sessionToken = await this.refreshToken(refreshToken);
          SessionStorage.setItem("sessionToken", sessionToken);
          request.headers.Authorization = `Bearer ${SessionStorage.getItem("sessionToken")}`;
          this.IS_LOCKED = false;
          return request;
        }

        return Promise.reject();
      }

      throw new CaremindClientException("000000", "not found token");
    });

    instance.interceptors.response.use(
      (res) => res,
      async (error: AxiosError<CaremindException>) => {
        const originalRequest = error.config;
        if (originalRequest && !API.IS_LOCKED && this.isExpiredToken(error.response)) {
          this.IS_LOCKED = true;

          const refreshToken = LocalStorage.getItem("refreshToken");

          if (refreshToken && API.CLIENT_ID) {
            const sessionToken = await this.refreshToken(refreshToken);
            originalRequest.headers.setAuthorization("Bearer " + sessionToken);
            SessionStorage.setItem("sessionToken", sessionToken);

            return instance(originalRequest);
          } else {
            throw new CaremindClientException("000001", "invalid refresh token");
          }
        }
        this.IS_LOCKED = false;
        return Promise.reject(error);
      },
    );

    return instance;
  };

  static get = <T>({ credential, headers, path, queryParams, cancelToken }: GetRequest): Observable<T> => {
    return from(
      this.createInstanceBy(credential).get(path, {
        headers,
        params: queryParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      }),
    ).pipe(
      map(({ data }) => data),
      catchError(this.throwError),
    );
  };
  static post = <T>({ credential, headers, path, queryParams, data, cancelToken }: PostRequest): Observable<T> => {
    return from(
      this.createInstanceBy(credential).post(path, data, {
        headers,
        params: queryParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      }),
    ).pipe(
      map(({ data }) => data),
      catchError(this.throwError),
    );
  };
  static put = <T>({ credential, headers, path, queryParams, data, cancelToken }: PutRequest): Observable<T> => {
    return from(
      this.createInstanceBy(credential).put(path, data, {
        headers,
        params: queryParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      }),
    ).pipe(
      map(({ data }) => data),
      catchError(this.throwError),
    );
  };
  static patch = <T>({ credential, headers, path, queryParams, data, cancelToken }: PatchRequest): Observable<T> => {
    return from(
      this.createInstanceBy(credential).patch(path, data, {
        headers,
        params: queryParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      }),
    ).pipe(
      map(({ data }) => data),
      catchError(this.throwError),
    );
  };
  static delete = <T>({ credential, headers, path, queryParams, cancelToken }: DeleteRequest): Observable<T> => {
    return from(
      this.createInstanceBy(credential).delete(path, {
        headers,
        params: queryParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      }),
    ).pipe(
      map(({ data }) => data),
      catchError(this.throwError),
    );
  };

  private static paramsSerializer: CustomParamsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: "comma" });
  };

  private static throwError = (error: AxiosError | any) => {
    if (error instanceof AxiosError) {
      return throwError(() => error.response?.data);
    }
    return throwError(() => error);
  };
}

interface Credential {
  credential: boolean;
}

interface Headers {
  headers?: RawAxiosRequestHeaders | AxiosHeaders;
}

interface Path {
  path: string;
}

interface QueryParam {
  queryParams?: Record<string, any>;
}

interface RequestBody {
  data?: any;
}

interface CancelToken {
  cancelToken?: AxiosCancelToken;
}

interface ContentType {}

type GetRequest = Credential & Headers & Path & QueryParam & CancelToken;
type PostRequest = Credential & Headers & Path & QueryParam & RequestBody & CancelToken;
type PutRequest = Credential & Headers & Path & QueryParam & RequestBody & CancelToken;
type PatchRequest = Credential & Headers & Path & QueryParam & RequestBody & CancelToken;
type DeleteRequest = Credential & Headers & Path & QueryParam & CancelToken;

interface CaremindException {
  http_method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  request_uri: string;
  error_code: string;
  error_message: string;
}
