import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import Calc from "../../utils/Calc";
import { Config } from "../../index.interface";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../../utils/Styles";

export class NoseLengthRatio45 extends DrawingFacialLandmarks {
  ratio: {
    noseRatio: number;
    jawRatio: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async (currentNoseLengthRatio: { noseRatio: number; jawRatio: number } | null) => {
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.photoBaseDrawer.drawGuideLines();
    await this.delay(300);
    if (currentNoseLengthRatio) {
      this.photoBaseDrawer.drawRatioText(currentNoseLengthRatio);
    }
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawGuideLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText({ noseRatio: 1, jawRatio: 1 });
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },
    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[4],
        point2: { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[152],
        point2: { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        point2: { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        point2: { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawRatioText: (currentNoseLengthRatio: { noseRatio: number; jawRatio: number } | null) => {
      const noseRatio = currentNoseLengthRatio?.noseRatio ? currentNoseLengthRatio.noseRatio : this.ratio.noseRatio;
      const jawRatio = currentNoseLengthRatio?.jawRatio ? currentNoseLengthRatio.jawRatio : this.ratio.jawRatio;

      const textStyle = { x: -27, y: 0, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(
        `${noseRatio}`,
        { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        textStyle,
      );

      this.drawTextBetweenPoints(
        `${jawRatio}`,
        { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        textStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: this.faceCoordinates[152],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },

    drawGuideLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[4],
        point2: { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[152],
        point2: { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[13],
        point2: { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        point2: { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        point2: { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },

    drawRatioText: (currentNoseLengthRatio: { noseRatio: number; jawRatio: number } | null) => {
      const noseRatio = currentNoseLengthRatio?.noseRatio ? currentNoseLengthRatio.noseRatio : this.ratio.noseRatio;
      const jawRatio = currentNoseLengthRatio?.jawRatio ? currentNoseLengthRatio.jawRatio : this.ratio.jawRatio;

      const textStyle = { x: -27, y: 25, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(
        `${noseRatio}`,
        { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - 150 },
        { ...this.faceCoordinates[4], x: this.faceCoordinates[168].x - 150 },
        textStyle,
      );

      this.drawTextBetweenPoints(
        `${jawRatio}`,
        { ...this.faceCoordinates[152], x: this.faceCoordinates[168].x - 150 },
        { ...this.faceCoordinates[13], x: this.faceCoordinates[168].x - 150 },
        textStyle,
      );
    },
  };

  private calcRatio = () => {
    const noseLengthDistance = Calc.calculateDistance(this.faceCoordinates[168], this.faceCoordinates[4], "2d");
    const jawLengthDistance = Calc.calculateDistance(this.faceCoordinates[152], this.faceCoordinates[13], "2d");

    return {
      noseRatio: Calc.calcRatio(noseLengthDistance, noseLengthDistance),
      jawRatio: Calc.calcRatio(noseLengthDistance, jawLengthDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
