import axios from "axios";
import { injectable } from "inversify";
import { catchError, from, map, Observable, of } from "rxjs";
import { SlackWebHook, SlackMessage } from "./model/SlackWebHook";

@injectable()
export default class SlackWebHookImpl implements SlackWebHook {
  notifySlackChannel = (data: { channelPath: string; slackMessage: SlackMessage }): Observable<void> => {
    const payload = {
      channelPath: data.channelPath,
      ...data.slackMessage,
    };

    return from(axios.post(`/api/slack/notification`, payload)).pipe(
      map(() => {
        return;
      }),
      catchError((error) => {
        throw error;
      }),
    );
  };
}
