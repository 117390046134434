import { Hospital, Payment } from "@/application/view-data";
import type { GetContract } from "@/domain/usecase/hospital/model/GetContract";
import type { GetInvoices } from "@/domain/usecase/invoice/model/GetInvoices";
import { injectable } from "inversify";
import { BehaviorSubject, mergeMap, Subject } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import { PlanSettingViewModel } from "./model/PlanSettingViewModel";
import Utils from "./util/ViewModelUtil";
@injectable()
export class PlanSettingViewModelImpl implements PlanSettingViewModel {
  output: PlanSettingViewModel["output"] = {
    planName: new BehaviorSubject<string | null>(null),
    period: new BehaviorSubject<Hospital.ContractPeriod | null>(null),
    faceFitPart: new BehaviorSubject<string | null>(null),
    openPlanInfo: new Subject<boolean>(),
    totalAccounts: new BehaviorSubject<number | null>(null),
    limit: new BehaviorSubject<number | null>(null),
    invoices: new BehaviorSubject<Payment.Invoice[]>([]),
  };

  route: PlanSettingViewModel["route"] = {
    toPlanOrderPage: new Subject<Payment.Invoice>(),
  };

  constructor(readonly uiSystem: UISystemManager, private readonly getContract: GetContract, private readonly ucGetInvoices: GetInvoices) {
    this.init();
  }

  private init = () => {
    this.getContract
      .execute()
      .pipe(
        mergeMap((contract) => {
          const availableFaceFitPart = this.setAvailableFaceFitPart(contract.plan.productCodes);
          const total = contract.plan.accounts.filter((account) => account.usage !== null).length;

          this.output.planName.next(contract.plan.name);
          this.output.period.next({
            startAt: { date: contract.period.startAt, "yy.mm.dd": Utils.formatDate(contract.period.startAt, "yy.mm.dd") },
            endAt: { date: contract.period.endAt, "yy.mm.dd": Utils.formatDate(contract.period.endAt, "yy.mm.dd") },
          });

          this.output.faceFitPart.next(availableFaceFitPart);
          this.output.totalAccounts.next(total);
          this.output.limit.next(contract.plan.accounts.length);

          return this.ucGetInvoices.execute({});
        }),
      )
      .subscribe({
        next: (orders) => {
          this.output.invoices.next(orders.items);
        },
        error: (error) => {
          if (error?.error_message === "user status is not activated") {
            this.uiSystem.errorHandler.alert.next({ message: "비활성화된 계정입니다.\n관리자에게 문의해주세요." });
          }
        },
      });
  };

  input: PlanSettingViewModel["input"] = {
    clickPayOrder: (item) => {
      this.route.toPlanOrderPage.next(item);
    },
    clickChangeSubscriptionCardData: (item) => {
      this.route.toPlanOrderPage.next(item);
    },
  };

  private setAvailableFaceFitPart = (productCodes: string[]) => {
    const productCode = Utils.formatProductCode(productCodes);
    let result = [];

    if (productCode.facefit?.operations?.eyes) {
      result.push("눈");
    }

    if (productCode.facefit?.operations?.nose) {
      result.push("코");
    }

    if (productCode.facefit?.operations?.facialcontouring) {
      result.push("윤곽");
    }

    return result.join(",");
  };
}

// const mockOrderData: Payment.OrderData[] = [
//   {
//     id: "123456",
//     clientKey: "client_001",
//     status: "READY",
//     paymentType: "ONE_TIME",
//     customer: {
//       key: "customer_001",
//       name: "홍길동",
//       email: "hong@example.com",
//       phoneNumber: "010-1234-5678",
//     },
//     amount: {
//       currency: "KRW",
//       value: 50000,
//     },
//     orderName: "상품 A",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "1234567",
//     clientKey: "client_001",
//     status: "READY",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_001",
//       name: "홍길동",
//       email: "hong@example.com",
//       phoneNumber: "010-1234-5678",
//     },
//     amount: {
//       currency: "KRW",
//       value: 50000,
//     },
//     orderName: "구독상품 A",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_2",
//     clientKey: "client_002",
//     status: "IN_PROGRESS",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_002",
//       name: "김철수",
//       email: "kim@example.com",
//       phoneNumber: "010-9876-5432",
//     },
//     amount: {
//       currency: "USD",
//       value: 40,
//     },
//     orderName: "구독 상품 B",
//     requestedAt: new Date(),
//     approvedAt: new Date(),
//   },
//   {
//     id: "order_3",
//     clientKey: "client_003",
//     status: "WAITING_FOR_DEPOSIT",
//     paymentType: "ONE_TIME",
//     customer: {
//       key: "customer_003",
//       name: "이영희",
//       email: "lee@example.com",
//       phoneNumber: "010-4567-8901",
//     },
//     amount: {
//       currency: "KRW",
//       value: 100000,
//     },
//     orderName: "상품 C",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_4",
//     clientKey: "client_004",
//     status: "DONE",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_004",
//       name: "박민수",
//       email: "park@example.com",
//       phoneNumber: "010-1122-3344",
//     },
//     amount: {
//       currency: "KRW",
//       value: 150000,
//     },
//     orderName: "프리미엄 상품 D",
//     requestedAt: new Date(),
//     approvedAt: new Date(),
//   },
//   {
//     id: "order_5",
//     clientKey: "client_005",
//     status: "CANCELED",
//     paymentType: "ONE_TIME",
//     customer: {
//       key: "customer_005",
//       name: "최유리",
//       email: "choi@example.com",
//       phoneNumber: "010-7788-9900",
//     },
//     amount: {
//       currency: "USD",
//       value: 60,
//     },
//     orderName: "상품 E",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_6",
//     clientKey: "client_006",
//     status: "PARTIAL_CANCELED",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_006",
//       name: "정다은",
//       email: "jung@example.com",
//       phoneNumber: "010-6677-8899",
//     },
//     amount: {
//       currency: "KRW",
//       value: 70000,
//     },
//     orderName: "상품 F",
//     requestedAt: new Date(),
//     approvedAt: new Date(),
//   },
//   {
//     id: "order_7",
//     clientKey: "client_007",
//     status: "ABORTED",
//     paymentType: "ONE_TIME",
//     customer: {
//       key: "customer_007",
//       name: "한지민",
//       email: "han@example.com",
//       phoneNumber: "010-3344-5566",
//     },
//     amount: {
//       currency: "USD",
//       value: 80,
//     },
//     orderName: "상품 G",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_8",
//     clientKey: "client_008",
//     status: "EXPIRED",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_008",
//       name: "강준혁",
//       email: "kang@example.com",
//       phoneNumber: "010-2233-4455",
//     },
//     amount: {
//       currency: "KRW",
//       value: 200000,
//     },
//     orderName: "구독 상품 H",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_9",
//     clientKey: "client_009",
//     status: "READY",
//     paymentType: "SUBSCRIPTION",
//     customer: {
//       key: "customer_009",
//       name: "윤소희",
//       email: "yoon@example.com",
//       phoneNumber: "010-8899-7766",
//     },
//     amount: {
//       currency: "KRW",
//       value: 30000,
//     },
//     orderName: "상품 I",
//     requestedAt: new Date(),
//     approvedAt: null,
//   },
//   {
//     id: "order_10",
//     clientKey: "client_010",
//     status: "DONE",
//     paymentType: "ONE_TIME",
//     customer: {
//       key: "customer_010",
//       name: "오세훈",
//       email: "oh@example.com",
//       phoneNumber: "010-1111-2222",
//     },
//     amount: {
//       currency: "USD",
//       value: 120,
//     },
//     orderName: "상품 J",
//     requestedAt: new Date(),
//     approvedAt: new Date(),
//   },
// ];
