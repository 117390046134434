import { injectable } from "inversify";
import { Observable } from "rxjs";
import { API } from "../../API";
import { InvoiceAPI } from "./model/InvoiceAPI";
import * as Request from "./model/Request";
import * as Response from "./model/Response";

@injectable()
export class InvoiceAPIImpl implements InvoiceAPI {
  getPaymentDetail = (request: Request.GetPaymentDetail): Observable<Response.GetPaymentDetail> => {
    return API.get({
      credential: false,
      path: `/dr-wo/v1/invoice/${request.invoiceKey}/payment`,
    });
  };

  getInvoices = (): Observable<Response.GetInvoices> => {
    return API.get({
      credential: true,
      path: `/dr-wo/v1/invoices`,
    });
  };

  confirmPaymentApproval = ({ invoiceKey, paymentInfo }: Request.ConfirmPaymentApproval): Observable<Response.ConfirmPaymentApproval> => {
    return API.post({
      credential: false,
      path: `/dr-wo/v1/invoice/${invoiceKey}/payment/approved`,
      data: {
        ...paymentInfo,
        paymentAmount: {
          total: paymentInfo.paymentAmount.total,
          currency: paymentInfo.paymentAmount.currency,
          tax: null,
          value: null,
        },
      },
    });
  };

  rejectPaymentApproval = ({ invoiceKey, ...payload }: Request.RejectPaymentApproval): Observable<Response.RejectPaymentApproval> => {
    return API.post({
      credential: false,
      path: `/dr-wo/v1/invoice/${invoiceKey}/payment/rejected`,
      data: payload,
    });
  };
}
