import { injectable } from "inversify";
import { FaceScanLeft45ViewModel } from "./model/FaceScanLeft45ViewModel";
import { Subject } from "rxjs";
import { FaceFit, FaceLandmark, Consulting } from "@/application/view-data";
import type { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { DrawingFacialLandmarks } from "./face-classification/face/DrawingFacialLandmarks";
import * as LeftNose45 from "./face-classification/nose/left-nose-45";

@injectable()
export class FaceScanLeft45ViewModelImpl implements FaceScanLeft45ViewModel {
  data: FaceScanLeft45ViewModel["data"] = {
    landmarks: null,
    faceScanResult: null,
  };
  output: FaceScanLeft45ViewModel["output"] = {
    landmarks: new Subject<FaceLandmark.Coordinate[][]>(),
  };

  constructor(private readonly ucGetFaceLandmarksByGCS: GetFaceLandmarks) {}

  event: FaceScanLeft45ViewModel["event"] = {
    onInit: (faceScanResult) => {
      const sub = this.ucGetFaceLandmarksByGCS.execute({ url: faceScanResult.faceLandmarksJSONURL }).subscribe({
        next: ({ landmarks }) => {
          this.data.faceScanResult = faceScanResult;
          this.data.landmarks = landmarks;
          this.output.landmarks.next(this.data.landmarks);
          sub.unsubscribe();
        },
        error: () => {
          sub.unsubscribe();
        },
      });
    },

    face: {
      onSetFaceLandmark: (photo, canvas) => {
        if (this.data.landmarks) {
          const drawingFacialLandmarks = new DrawingFacialLandmarks({
            imageEle: photo,
            canvasEle: canvas,
            normalizedCoordinate: this.data.landmarks,
          });

          drawingFacialLandmarks.drawLandmarkPoints();
        }
      },
    },

    nose: {
      onScanNoseLength: (canvasConfig, currentNoseLengthRatio) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.NoseLengthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });

          const bestScan = new LeftNose45.NoseLengthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          currentScan.drawPhotoBase(currentNoseLengthRatio);
          bestScan.drawBestRatio();
        }
      },
      onScanNoseTipHeight: (canvasConfig, currentNoseTipHeightRatio) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.NoseTipHeightRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.NoseTipHeightRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          currentScan.drawPhotoBase(currentNoseTipHeightRatio);
          bestScan.drawBestRatio();
        }
      },

      onScanForeheadNoseAngle45: (canvasConfig, currentForeheadNoseAngle) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.ForeheadNoseAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.ForeheadNoseAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          currentScan.drawPhotoBase(currentForeheadNoseAngle);
          bestScan.drawBestRatio();
        }
      },

      onScanNasolabialAngle45: (canvasConfig, currentNasolabialAngle) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.NasolabialAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.NasolabialAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          if (currentNasolabialAngle?.angle) {
            currentScan.drawPhotoBase(currentNasolabialAngle?.angle);
          }
          bestScan.drawBestRatio();
        }
      },

      onScanNoseTipShape45: (canvasConfig, currentNoseTipRatio) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.NoseTipShape45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.NoseTipShape45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });
          currentScan.drawPhotoBase(currentNoseTipRatio);
          bestScan.drawBestRatio();
        }
      },

      onScanNoseBridgeWidthRatio45: (canvasConfig, currentNoseBridgeWidthRatio) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.NoseBridgeWidthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.NoseBridgeWidthRatio45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          if (currentNoseBridgeWidthRatio) {
            currentScan.drawPhotoBase(currentNoseBridgeWidthRatio);
          }
          bestScan.drawBestRatio();
        }
      },

      onScanColumellarLobularAngle45: (canvasConfig, angle) => {
        if (this.data.landmarks) {
          const currentScan = new LeftNose45.ColumellarLobularAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.beforeCanvas, normalizedCoordinate: this.data.landmarks },
          });
          const bestScan = new LeftNose45.ColumellarLobularAngle45({
            config: { imageEle: canvasConfig.photo, canvasEle: canvasConfig.bestCanvas, normalizedCoordinate: this.data.landmarks },
          });

          if (angle?.angle) {
            currentScan.drawPhotoBase({ angle: angle?.angle });
          }
          bestScan.drawBestRatio();
        }
      },
    },
  };
}
