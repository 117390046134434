import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { InvoiceRepository } from "@/domain/interactor/repository/InvoiceRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetInvoicePayment, GetInvoicePaymentInput, GetInvoicePaymentOutput } from "./model/GetInvoicePayment";

@injectable()
export class GetInvoicePaymentImpl implements GetInvoicePayment {
  constructor(@inject(TYPES.InvoiceRepository) private readonly invoiceRepository: InvoiceRepository) {}

  execute(input: GetInvoicePaymentInput): Observable<GetInvoicePaymentOutput> {
    return this.invoiceRepository.getPaymentDetail(input.invoiceKey);
  }
}
