import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import { Config, Coordinate } from "../../index.interface";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../../utils/Styles";
import Calc from "../../utils/Calc";
export class NoseTipHeightRatio45 extends DrawingFacialLandmarks {
  private coordinate = {
    top: this.faceCoordinates[168],
    center: this.faceCoordinates[4],
    bottom: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6 },
  };
  ratio: {
    noseLength: number;
    noseHeight: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async (ratio: { noseLength: number; noseHeight: number } | null) => {
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.photoBaseDrawer.drawGuideLine();
    await this.delay(300);
    if (ratio) {
      this.photoBaseDrawer.drawRatioText(ratio);
    }
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawGuideLine();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom,
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
    },
    drawGuideLine: () => {
      const leftGuideLine = {
        top: { ...this.coordinate.center, y: this.coordinate.bottom.y + 40 },
        bottom: { ...this.coordinate.center, y: this.coordinate.bottom.y - 40 },
      };
      const rightGuideLine = {
        top: { ...this.coordinate.bottom, y: this.coordinate.bottom.y + 40 },
        bottom: { ...this.coordinate.bottom, y: this.coordinate.bottom.y - 40 },
      };
      this.drawLineBetweenPoints({
        point1: leftGuideLine.top,
        point2: leftGuideLine.bottom,
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: rightGuideLine.top,
        point2: rightGuideLine.bottom,
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawRatioText: (ratio: { noseLength: number; noseHeight: number }) => {
      const lengthTextStyle = { x: 40, y: 0, fontSize: 18, color: "#FF9900" };
      const heightTextStyle = { x: 2, y: 50, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${1}`, this.faceCoordinates[168], this.faceCoordinates[4], lengthTextStyle);
      this.drawTextBetweenPoints(
        `${parseFloat((ratio.noseHeight * 100).toFixed(2)).toString()}%`,
        this.faceCoordinates[4],
        this.faceCoordinates[102],
        heightTextStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom,
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawGuideLine: () => {
      const leftGuideLine = {
        top: { ...this.coordinate.center, y: this.coordinate.bottom.y + 40 },
        bottom: { ...this.coordinate.center, y: this.coordinate.bottom.y - 40 },
      };
      const rightGuideLine = {
        top: { ...this.coordinate.bottom, y: this.coordinate.bottom.y + 40 },
        bottom: { ...this.coordinate.bottom, y: this.coordinate.bottom.y - 40 },
      };
      this.drawLineBetweenPoints({
        point1: leftGuideLine.top,
        point2: leftGuideLine.bottom,
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: rightGuideLine.top,
        point2: rightGuideLine.bottom,
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    },
    drawRatioText: () => {
      const lengthTextStyle = { x: 60, y: 0, fontSize: 18, color: "#00D7CA" };
      const heightTextStyle = { x: 2, y: 75, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${1}`, this.faceCoordinates[168], this.faceCoordinates[4], lengthTextStyle);
      this.drawTextBetweenPoints("67%", this.faceCoordinates[4], this.faceCoordinates[102], heightTextStyle);
    },
  };

  private calcRatio = () => {
    const noseLengthDistance = Calc.calculateDistance(this.faceCoordinates[168], this.faceCoordinates[4], "2d");
    const noseHeightDistance = Calc.calculateDistance(this.faceCoordinates[102], this.faceCoordinates[4], "2d");

    return {
      noseLength: 1,
      noseHeight: Calc.calcRatio(noseLengthDistance, noseHeightDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
