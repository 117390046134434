import { injectable } from "inversify";
import { EventName, EventManager } from ".";

@injectable()
export default class EventManagerImpl implements EventManager {
  add(eventName: EventName, handler: EventListenerOrEventListenerObject) {
    window.addEventListener(eventName, handler);
  }
  remove(eventName: EventName, handler: EventListenerOrEventListenerObject) {
    window.removeEventListener(eventName, handler);
  }
  dispatch(eventName: EventName, detail?: any) {
    const event = new CustomEvent(eventName, { detail });
    window.dispatchEvent(event);
  }
}
