import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { Config, Coordinate } from "../index.interface";
import { BestRatioLineStyle, PhotoBaseLine } from "../utils/Styles";

export class ForeheadNoseAngle extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    top: Coordinate;
    middle: Coordinate;
    bottom: Coordinate;
  };

  ratio: {
    angle: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    // this.photoBaseDrawer.drawNoseLine();
    this.photoBaseDrawer.drawFrontalFaceLines();
    await this.delay(300);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    // this.bestRatioDrawer.drawNoseLine();
    this.bestRatioDrawer.drawFrontalFaceLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawNoseLine: () => {
      this.drawLineByCoordinates([this.coordinate.top, this.coordinate.middle, this.coordinate.bottom], PhotoBaseLine, true);
      this.drawArcBetweenPoints({
        center: this.coordinate.middle,
        point1: this.coordinate.top,
        point2: this.coordinate.bottom,
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },
    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[168],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawArcBetweenPoints({
        center: this.faceCoordinates[168],
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[4],
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },

    drawRatioText: () => {
      const textStyle = { x: 75, y: -5, fontSize: 18, color: "#FF9900" };
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${this.ratio.angle}°`,
        this.faceCoordinates[168],
        this.faceCoordinates[4],
        textStyle,
      );
      // this.drawTextBetweenPoints(`${this.ratio.angle}°`, this.coordinate.top, this.coordinate.middle, textStyle);
    },
  };

  private bestRatioDrawer = {
    drawNoseLine: () => {
      this.drawLineByCoordinates([this.coordinate.top, this.coordinate.middle, this.coordinate.bottom], BestRatioLineStyle, true);
      this.drawArcBetweenPoints({
        center: this.coordinate.middle,
        point1: this.coordinate.top,
        point2: this.coordinate.bottom,
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },

    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[168],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[168],
        point2: this.faceCoordinates[4],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawArcBetweenPoints({
        center: this.faceCoordinates[168],
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[4],
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },

    drawRatioText: () => {
      const textStyle = { x: 62, y: 20, fontSize: 18, color: "#00D7CA" };
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(`${150}°`, this.faceCoordinates[168], this.faceCoordinates[4], textStyle);
      // const textStyle = { x: 34, y: -30, fontSize: 18, color: "#00D7CA" };
      // this.drawTextBetweenPoints(`${150}°`, this.coordinate.top, this.coordinate.middle, textStyle);
    },
  };

  private getCoordinate = () => {
    const offsetX = 30;

    return {
      top: { ...this.sideFaceCoordinate[9], x: this.sideFaceCoordinate[9].x + offsetX },
      middle: { ...this.sideFaceCoordinate[168], x: this.sideFaceCoordinate[168].x + offsetX },
      bottom: { ...this.sideFaceCoordinate[5], x: this.sideFaceCoordinate[5].x + offsetX },
    };
  };

  private calcRatio = () => {
    const angle = Calc.calcAngle({ point1: this.coordinate.top, center: this.coordinate.middle, point2: this.coordinate.bottom }, "2d");
    return {
      angle,
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
