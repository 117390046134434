import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { MenuProps } from "@mui/material";

export interface BasicMenuProps {
  button: React.ReactNode;
  items: {
    component: React.ReactNode;
    click: () => void;
  }[];
  disabled?: boolean;
  paperStyle?: React.CSSProperties;
  anchorOrigin?: MenuProps["anchorOrigin"];
  transformOrigin?: MenuProps["transformOrigin"];
  setIsOpen?: (isOpen: boolean) => void;
}

const BasicMenu: React.FC<BasicMenuProps> = ({ button, items, disabled, paperStyle, anchorOrigin, transformOrigin, setIsOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen && setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen && setIsOpen(false);
    setAnchorEl(null);
  };

  return (
    <BasicMenuLayout>
      <Button
        sx={{ padding: 0, width: "100%", display: "block", minWidth: "auto" }}
        disabled={disabled}
        onClick={handleClick}
      >
        {button}
      </Button>
      {open ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          sx={{
            "& .MuiPaper-root": {
              padding: "0px",
              backgroundColor: "inherit",
              ...paperStyle,
            },
            "& .MuiList-root": {
              padding: "0px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#fff",
            },
          }}
        >
          {items.map((item, idx) => {
            return (
              <CustomMenuItem
                key={idx}
                sx={{ padding: 0 }}
                onClick={() => {
                  handleClose();
                  item.click();
                }}
              >
                {item.component}
              </CustomMenuItem>
            );
          })}
        </Menu>
      ) : null}
    </BasicMenuLayout>
  );
};

export default BasicMenu;

const BasicMenuLayout = styled.div`
  width: 100%;
  z-index: 600;
`;

const CustomMenuItem = styled(MenuItem)`
  min-height: 32px !important;
  width: 100%;

  &:hover {
    background-color: #f3f4f0 !important;
    color: #14443f !important;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    padding: 0px;
    background-color: inherit;
    border-radius: 6px;
  }
`;
