import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import { Config, Coordinate } from "../../index.interface";
import Calc from "../../utils/Calc";
import { BestRatioLineStyle, PhotoBaseLine } from "@view-model/face-classification/utils/Styles";
export class NasolabialAngle45 extends DrawingFacialLandmarks {
  constructor({ config }: { config: Config }) {
    super(config);
  }

  drawPhotoBase = async (angle: number) => {
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.photoBaseDrawer.drawAngleText(angle);
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawAngleText(95);
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
        point1: this.faceCoordinates[59],
        point2: this.faceCoordinates[44],
        length: 60,
        direction: "RIGHT",
      });

      const baseTopLine = { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y - 60 };
      const baseBottomLine = { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y + 40 };

      this.drawArcBetweenPoints({
        center: this.faceCoordinates[59],
        point1: this.faceCoordinates[44],
        point2: baseBottomLine,
        radius: 15,
        lineStyle: PhotoBaseLine,
      });

      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[59],
        point2: coordinateByInclinationToRight,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: baseTopLine,
        point2: baseBottomLine,
        lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
        isDrawEndPoint: true,
      });
    },

    drawAngleText: (angle: number) => {
      const textStyle = { x: -40, y: 0, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(
        `${angle}°`,
        this.faceCoordinates[59],
        { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y - 10 },
        textStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
        point1: this.faceCoordinates[59],
        point2: this.faceCoordinates[44],
        length: 60,
        direction: "RIGHT",
      });

      const baseTopLine = { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y - 60 };
      const baseBottomLine = { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y + 40 };

      this.drawArcBetweenPoints({
        center: this.faceCoordinates[59],
        point1: this.faceCoordinates[44],
        point2: baseBottomLine,
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });

      this.drawLineBetweenPoints({
        point1: this.faceCoordinates[59],
        point2: coordinateByInclinationToRight,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: baseTopLine,
        point2: baseBottomLine,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });
    },

    drawAngleText: (angle: number) => {
      const textStyle = { x: -35, y: 25, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(
        `${angle}°`,
        this.faceCoordinates[59],
        { ...this.faceCoordinates[59], y: this.faceCoordinates[59].y - 10 },
        textStyle,
      );
    },
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
