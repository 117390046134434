import { Coordinate } from "../..";
import { DrawingFacialLandmarks } from "../../face/DrawingFacialLandmarks";
import { Config } from "../../index.interface";
import Calc from "../../utils/Calc";
import { BestRatioLineStyle, PhotoBaseLine } from "../../utils/Styles";

//비주소엽각
export class ColumellarLobularAngle45 extends DrawingFacialLandmarks {
  private coordinate: {
    top: {
      top: Coordinate;
      bottom: Coordinate;
    };
    bottom: {
      top: Coordinate;
      bottom: Coordinate;
    };
  };
  ratio: {
    angle: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);

    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async ({ angle }: { angle: number }) => {
    // this.photoBaseDrawer.drawNoseLines();
    this.photoBaseDrawer.drawFrontalFaceLines(angle);
    await this.delay(300);
    this.photoBaseDrawer.drawAngleText(angle);
  };

  drawBestRatio = async () => {
    // this.bestRatioDrawer.drawNoseLines();
    this.bestRatioDrawer.drawFrontalFaceLines();
    await this.delay(300);
    this.bestRatioDrawer.drawAngleText();
  };

  private photoBaseDrawer = {
    // drawNoseLines: () => {
    //   this.drawLineBetweenPoints({
    //     point1: this.coordinate.top.top,
    //     point2: this.coordinate.top.bottom,
    //     lineStyle: PhotoBaseLine,
    //     isDrawEndPoint: true,
    //   });
    //   this.drawLineBetweenPoints({
    //     point1: this.coordinate.bottom.top,
    //     point2: this.coordinate.bottom.bottom,
    //     lineStyle: PhotoBaseLine,
    //     isDrawEndPoint: true,
    //   });
    //   this.drawArcBetweenPoints({
    //     center: this.coordinate.top.bottom,
    //     point1: this.coordinate.top.top,
    //     point2: this.coordinate.bottom.bottom,
    //     radius: 15,
    //     lineStyle: PhotoBaseLine,
    //   });
    // },

    drawAngleText: (angle: number) => {
      const textStyle = { x: 70, y: 0, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${angle}°`, this.faceCoordinates[2], this.faceCoordinates[4], textStyle);
    },

    drawFrontalFaceLines: (angle: number) => {
      const coordinate2 = {
        ...this.faceCoordinates[2],
        x: this.faceCoordinates[0].x > this.faceCoordinates[2].x ? this.faceCoordinates[0].x + 10 : this.faceCoordinates[2].x + 10,
        y: this.faceCoordinates[2].y - 3,
      };
      const coordinate = Calc.getCoordinateByAngle(
        this.faceCoordinates[0],
        this.faceCoordinates[0].x > this.faceCoordinates[2].x ? coordinate2 : this.faceCoordinates[2],
        angle,
        "RIGHT",
      );
      this.drawLineBetweenPoints({
        point1: coordinate,
        point2: coordinate2,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: coordinate2,
        point2: this.faceCoordinates[0],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: coordinate2,
        point1: coordinate,
        point2: this.faceCoordinates[0],
        radius: 15,
        lineStyle: PhotoBaseLine,
        reverse: true,
      });
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom.top,
        point2: this.coordinate.bottom.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.top.bottom,
        point2: this.coordinate.top.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: this.faceCoordinates[2],
        point1: this.faceCoordinates[4],
        point2: this.faceCoordinates[0],
        radius: 15,
        lineStyle: PhotoBaseLine,
        reverse: true,
      });
      Calc.calculateCoordinateByAngleAndDirection;
    },

    drawAngleText: () => {
      const textStyle = { x: 59, y: 25, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`95°`, this.faceCoordinates[2], this.faceCoordinates[4], textStyle);
      // const textStyle = { x: 10, y: 57, fontSize: 18, color: "#00D7CA" };
      // this.drawTextBetweenPoints(`${97}°`, this.coordinate.top.top, this.coordinate.top.bottom, textStyle);
    },

    drawFrontalFaceLines: () => {
      const coordinate2 = {
        ...this.faceCoordinates[2],
        x: this.faceCoordinates[0].x > this.faceCoordinates[2].x ? this.faceCoordinates[0].x + 10 : this.faceCoordinates[2].x + 10,
        y: this.faceCoordinates[2].y - 3,
      };
      const coordinate = Calc.getCoordinateByAngle(
        this.faceCoordinates[0],
        this.faceCoordinates[0].x > this.faceCoordinates[2].x ? coordinate2 : this.faceCoordinates[2],
        95,
        "RIGHT",
      );
      // const coordinate = Calc.getCoordinateByAngle(this.faceCoordinates[0], this.faceCoordinates[2], 95, "RIGHT");
      this.drawLineBetweenPoints({
        point1: coordinate,
        point2: coordinate2,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: coordinate2,
        point2: this.faceCoordinates[0],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: coordinate2,
        point1: coordinate,
        point2: this.faceCoordinates[0],
        radius: 15,
        lineStyle: BestRatioLineStyle,
        reverse: true,
      });
    },
  };

  private getCoordinate = (): {
    top: {
      top: Coordinate;
      bottom: Coordinate;
    };
    bottom: {
      top: Coordinate;
      bottom: Coordinate;
    };
  } => {
    const coordinateByInclinationToLeft = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[2],
      point2: this.sideFaceCoordinate[19],
      length: 40,
      direction: "RIGHT",
    });

    const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[2],
      point2: this.sideFaceCoordinate[0],
      length: 30,
      direction: "RIGHT",
    });

    return {
      top: {
        top: coordinateByInclinationToLeft,
        bottom: this.sideFaceCoordinate[2],
      },
      bottom: {
        top: this.sideFaceCoordinate[2],
        bottom: coordinateByInclinationToRight,
      },
    };
  };

  private calcRatio = () => {
    const angle = Calc.calcAngle(
      {
        point1: this.coordinate.top.top,
        center: this.coordinate.top.bottom,
        point2: this.coordinate.bottom.bottom,
      },
      "2d",
    );

    return {
      angle,
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
