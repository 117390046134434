import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { InvoiceRepository } from "@/domain/interactor/repository/InvoiceRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import {
    RequestPaymentApproval,
    RequestPaymentApprovalInput,
    RequestPaymentApprovalOutput,
} from "./model/RequestPaymentApproval";

@injectable()
export class RequestPaymentApprovalImpl implements RequestPaymentApproval {
  constructor(@inject(TYPES.InvoiceRepository) private readonly invoiceRepository: InvoiceRepository) {}

  execute(input: RequestPaymentApprovalInput): Observable<RequestPaymentApprovalOutput> {
    return this.invoiceRepository.confirmPaymentApproval(input.invoiceKey, input.paymentInfo);
  }
}
