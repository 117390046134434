import { ContainerModule, interfaces } from "inversify";
import { GetConsultingNotesByAdvertiseApplyImpl } from "./advertisement/GetConsultingNotesByAdvertiseApplyImpl";
import { GetConsultingNotesByAdvertiseApply } from "./advertisement/model/GetConsultingNotesByAdvertiseApply";
import { UpdateApplicationStatusByAdvertise } from "./advertisement/model/UpdateApplicationStatusByAdvertise";
import { UpdateReservationDateByAdvertise } from "./advertisement/model/UpdateReservationDateByAdvertise";
import { UpdateReservationMemoByAdvertise } from "./advertisement/model/UpdateReservationMemoByAdvertise";
import { UpdateReservationStatusByAdvertise } from "./advertisement/model/UpdateReservationStatusByAdvertise";
import { UpdateApplicationStatusByAdvertiseImpl } from "./advertisement/UpdateApplicationStatusByAdvertiseImpl";
import { UpdateReservationDateByAdvertiseImpl } from "./advertisement/UpdateReservationDateByAdvertiseImpl";
import { UpdateReservationMemoByAdvertiseImpl } from "./advertisement/UpdateReservationMemoByAdvertiseImpl";
import { UpdateReservationStatusByAdvertiseImpl } from "./advertisement/UpdateReservationStatusByAdvertiseImpl";
import { GetUserV2Impl } from "./auth/GetUserV2Impl";
import { LoginImpl } from "./auth/LoginImpl";
import { LogoutImpl } from "./auth/LogoutImpl";
import { GetUserV2 } from "./auth/model/GetUserV2";
import { Login } from "./auth/model/Login";
import { Logout } from "./auth/model/Logout";
import { DrawConnectorsByMediaPipeImpl } from "./classification/DrawConnectorsByMediaPipeImpl";
import { DrawConnectorsByMediaPipe } from "./classification/model/DrawConnectorsByMediaPipe";
import { CreateConsultingNoteImpl } from "./consulting/CreateConsultingNoteImpl";
import { GetAIAnalysisImpl } from "./consulting/GetAIAnalysisImpl";
import { GetBestPracticePhotosImpl } from "./consulting/GetBestPracticePhotosImpl";
import { GetBestPracticesImpl } from "./consulting/GetBestPracticesImpl";
import { GetConsultantsByFaceFitImpl } from "./consulting/GetConsultantsByFaceFitImpl";
import { GetConsultingNoteImpl } from "./consulting/GetConsultingNoteImpl";
import { GetConsultingNotesImpl } from "./consulting/GetConsultingNotesImpl";
import { GetInfluencerPhotosImpl } from "./consulting/GetInfluencerPhotosImpl";
import { CreateConsultingNote } from "./consulting/model/CreateConsultingNote";
import { GetAIAnalysis } from "./consulting/model/GetAIAnalysis";
import { GetBestPracticePhotos } from "./consulting/model/GetBestPracticePhotos";
import { GetBestPractices } from "./consulting/model/GetBestPractices";
import { GetConsultantsByFaceFit } from "./consulting/model/GetConsultantsByFaceFit";
import { GetConsultingNote } from "./consulting/model/GetConsultingNote";
import { GetConsultingNotes } from "./consulting/model/GetConsultingNotes";
import { GetInfluencerPhotos } from "./consulting/model/GetInfluencerPhotos";
import { RequestPersonaShift } from "./consulting/model/ReqeustPersonaShift";
import { ReqeustFaceLandmark } from "./consulting/model/RequestFaceLandmark";
import { RequestFaceSimilarity } from "./consulting/model/RequestFaceSimilarity";
import { UpdateBestPracticeExposureStatus } from "./consulting/model/UpdateBestPracticeExposureStatus";
import { UpdateConsultingMemo } from "./consulting/model/UpdateConsultingMemo";
import { UpdateConsultingNote } from "./consulting/model/UpdateConsultingNote";
import { UpdateConsultingReservationDate } from "./consulting/model/UpdateConsultingReservationDate";
import { UpdateConsultingStatus } from "./consulting/model/UpdateConsultingStatus";
import { UpdateOperationReservationStatus } from "./consulting/model/UpdateOperationReservationStatus";
import { UpdateReservationStatus } from "./consulting/model/UpdateReservationStatus";
import { UploadConsultingPhoto } from "./consulting/model/UploadConsultingPhoto";
import { RequestFaceLandmarkImpl } from "./consulting/RequestFaceLandmarkImpl";
import { RequestFaceSimilarityImpl } from "./consulting/RequestFaceSimilarityImpl";
import { RequestPersonaShiftImpl } from "./consulting/RequestPersonaShiftImpl";
import { UpdateBestPracticeExposureStatusImpl } from "./consulting/UpdateBestPracticeExposureStatusImpl";
import { UpdateConsultingMemoImpl } from "./consulting/UpdateConsultingMemoImpl";
import { UpdateConsultingNoteImpl } from "./consulting/UpdateConsultingNoteImpl";
import { UpdateConsultingReservationDateImpl } from "./consulting/UpdateConsultingReservationDateImpl";
import { UpdateConsultingStatusImpl } from "./consulting/UpdateConsultingStatusImpl";
import { UpdateOperationReservationStatusImpl } from "./consulting/UpdateOperationReservationStatusImpl";
import { UpdateReservationStatusImpl } from "./consulting/UpdateReservationStatusImpl";
import { UploadConsultingPhotoImpl } from "./consulting/UploadConsultingPhotoImpl";
import { AddDoctorImpl } from "./doctor/AddDoctorImpl";
import { DeleteDoctorImpl } from "./doctor/DeleteDoctorImpl";
import { GetDoctorImpl } from "./doctor/GetDoctorImpl";
import { GetDoctorsImpl } from "./doctor/GetDoctorsImpl";
import { AddDoctor } from "./doctor/model/AddDoctor";
import { DeleteDoctor } from "./doctor/model/DeleteDoctor";
import { GetDoctor } from "./doctor/model/GetDoctor";
import { GetDoctors } from "./doctor/model/GetDoctors";
import { UpdateDoctor } from "./doctor/model/UpdateDoctor";
import { UpdateDoctorImpl } from "./doctor/UpdateDoctorImpl";
import { GetFaceLandmarksImpl } from "./gcs/GetFaceLandmarksImpl";
import { GetFaceLandmarks } from "./gcs/model/GetFaceLandmarks";
import { UploadImage } from "./gcs/model/UploadImage";
import { UploadImageImpl } from "./gcs/UploadImageImpl";
import { CreateAccountImpl } from "./hospital/CreateAccountImpl";
import { GetAccountLoginIdValidationImpl } from "./hospital/GetAccountLoginIdValidationImpl";
import { GetContractImpl } from "./hospital/GetContractImpl";
import { GetHospitalImpl } from "./hospital/GetHospitalImpl";
import { GetOperationCategoriesImpl } from "./hospital/GetOperationCategoriesImpl";
import { CreateAccount } from "./hospital/model/CreateAccount";
import { GetAccountLoginIdValidation } from "./hospital/model/GetAccountLoginIdValidation";
import { GetContract } from "./hospital/model/GetContract";
import { GetHospital } from "./hospital/model/GetHospital";
import { GetOperationCategories } from "./hospital/model/GetOperationCategories";
import { UpdateAccount } from "./hospital/model/UpdateAccount";
import { UpdateHospital } from "./hospital/model/UpdateHospital";
import { UpdateAccountImpl } from "./hospital/UpdateAccountImpl";
import { UpdateHospitalImpl } from "./hospital/UpdateHospital";
import { TYPES } from "./index.types";
import { GetNoticesImpl } from "./notice/impl/GetNoticesImpl";
import { GetNotices } from "./notice/model/GetNotices";
import { GetTags } from "./consulting/model/GetTags";
import { GetTagsImpl } from "./consulting/GetTagsImpl";
import { GetFaceStyles } from "./consulting/model/GetFaceStyles";
import { GetFaceStylesImpl } from "./consulting/GetFaceStylesImpl";
import { GetOperationCategoryGroups } from "./consulting/model/GetOperationCategoryGroups";
import { GetOperationCategoryGroupsImpl } from "./consulting/GetOperationCategoryGroupsImpl";
import { GetSignedURL } from "./gcs/model/GetSignedURL";
import { GetSignedURLImpl } from "./gcs/GetSignedURLImpl";
import { CreateConsultationRecording } from "./consulting/model/CreateConsultationRecording";
import { CreateConsultationRecordingImpl } from "./consulting/CreateConsultationRecordingImpl";
import { GetConsultationRecording } from "./consulting/model/GetConsultationRecording";
import { GetConsultationRecordingImpl } from "./consulting/GetConsultationRecordingImpl";
import { CreateConsultingTranscriptions } from "./consulting/model/CreateConsultingTranscriptions";
import { CreateConsultingTranscriptionsImpl } from "./consulting/CreateConsultingTranscriptionsImpl";
import { CreateOperationConsultingLog } from "./consulting/model/CreateOperationConsultingLog";
import { CreateOperationConsultingLogImpl } from "./consulting/CreateOperationConsultingLogImpl";
import { GetConsultationKeywords } from "./consulting/model/GetConsultationKeywords";
import { GetConsultationKeywordsImpl } from "./consulting/GetConsultationKeywordsImpl";
import { CreateConsultingTranscriptionAnalysis } from "./consulting/model/CreateConsultingTranscriptionAnalysis";
import { CreateConsultingTranscriptionAnalysisImpl } from "./consulting/CreateConsultingTranscriptionAnalysisImpl";
import { RequestLandmarksForUnextractedPhotos } from "./consulting/model/RequestLandmarksForUnextractedPhotos";
import { RequestLandmarksForUnextractedPhotosImpl } from "./consulting/RequestLandmarksForUnextractedPhotosImpl";
import { GetConsultingAnalyticsGroupByConsultant } from "./analytics/model/GetConsultingAnalyticsGroupByConsultant";
import { GetConsultingAnalyticsGroupByDateImpl } from "./analytics/GetConsultingAnalyticsGroupByDateImpl";
import { GetConsultingAnalyticsGroupByConsultantImpl } from "./analytics/GetConsultingAnalyticsGroupByConsultantImpl";
import { GetConsultingAnalyticsGroupByDate } from "./analytics/model/GetConsultingAnalyticsGroupByDate";
import { GetConsultingAnalyticsFirstCreatedDate } from "./analytics/model/GetConsultingAnalyticsFirstCreatedDate";
import { GetConsultingAnalyticsFirstCreatedDateImpl } from "./analytics/GetConsultingAnalyticsFirstCreatedDateImpl";
import { CreateBestPractice } from "./consulting/model/CreateBestPractice";
import { CreateBestPracticeImpl } from "./consulting/CreateBestPracticeImpl";
import { GetBestPractice } from "./consulting/model/GetBestPractice";
import { GetBestPracticeImpl } from "./consulting/GetBestPracticeImpl";
import { UpdateBestPractice } from "./consulting/model/UpdateBestPractice";
import { UpdateBestPracticeImpl } from "./consulting/UpdateBestPracticeImpl";
import { DeleteBestPractice } from "./consulting/model/DeleteBestPractice";
import { DeleteBestPracticeImpl } from "./consulting/DeleteBestPracticeImpl";
import { EmbeddingExtractionBestPractice } from "./consulting/model/EmbeddingExtractionBestPractice";
import { EmbeddingExtractionBestPracticeImpl } from "./consulting/EmbeddingExtractionBestPracticeImpl";
import { GetHospitalFaceFitConfiguration } from "./consulting/model/GetHospitalFaceFitConfiguration";
import { GetHospitalFaceFitConfigurationImpl } from "./consulting/GetHospitalFaceFitConfigurationImpl";
import { UpdateHospitalFaceFitConfiguration } from "./consulting/model/UpdateHospitalFaceFitConfiguration";
import { UpdateHospitalFaceFitConfigurationImpl } from "./consulting/UpdateHospitalFaceFitConfigurationImpl";
import { GetConsultingNoteByNoteNumber } from "./consulting/model/GetConsultingNotebyNoteNumber";
import { GetConsultingNoteByNoteNumberImpl } from "./consulting/GetConsultingNoteByNoteNumberImpl";
import { DeleteConsultingNotes } from "./consulting/model/DeleteConsultingNotes";
import { DeleteConsultingNotesImpl } from "./consulting/DeleteConsultingNotesImpl";
import { UpdateConsultingNoteConsultant } from "./consulting/model/UpdateConsultingNoteConsultant";
import { UpdateConsultingNoteConsultantImpl } from "./consulting/UpdateConsultingNoteConsultantImpl";
import { RejectPaymentApproval } from "./invoice/model/RejectPaymentApproval";
import { RejectPaymentApprovalImpl } from "./invoice/RejectPaymentApprovalImpl";
import { NotifyPaymentErrorMessage } from "./invoice/model/NotifyPaymentErrorMessage";
import { NotifyPaymentErrorMessageImpl } from "./invoice/NotifyPaymentErrorMessageImpl";
import { GetInvoices } from "./invoice/model/GetInvoices";
import { GetInvoicePayment } from "./invoice/model/GetInvoicePayment";
import { GetInvoicePaymentImpl } from "./invoice/GetInvoicePaymentImpl";
import { GetInvoicesImpl } from "./invoice/GetInvoicesImpl";
import { RequestPaymentApprovalImpl } from "./invoice/RequestPaymentApprovalImpl";
import { RequestPaymentApproval } from "./invoice/model/RequestPaymentApproval";

// export const UCDoctor = new ContainerModule((bind: interfaces.Bind) => {
//   bind<GetDoctors>(TYPES.DOCTOR.GetDoctors).to(GetDoctorsImpl);
//   bind<GetDoctor>(TYPES.DOCTOR.GetDoctor).to(GetDoctorImpl);
//   bind<AddDoctor>(TYPES.DOCTOR.AddDoctor).to(AddDoctorImpl);
//   bind<UpdateDoctor>(TYPES.DOCTOR.UpdateDoctor).to(UpdateDoctorImpl);
//   bind<DeleteDoctor>(TYPES.DOCTOR.DeleteDoctor).to(DeleteDoctorImpl);
// });

const useCases = new ContainerModule((bind: interfaces.Bind) => {
  bind<Login>("Login").to(LoginImpl);
  bind<Logout>("Logout").to(LogoutImpl);
  bind<GetUserV2>("GetUserV2").to(GetUserV2Impl);
  bind<GetNotices>("GetNotices").to(GetNoticesImpl);

  //hospital
  bind<GetOperationCategories>("GetOperationCategories").to(GetOperationCategoriesImpl);
  bind<GetHospital>(TYPES.GetHospital).to(GetHospitalImpl);
  bind<GetContract>(TYPES.GetContract).to(GetContractImpl);
  bind<UpdateHospital>(TYPES.UpdateHospital).to(UpdateHospitalImpl);
  bind<CreateAccount>(TYPES.CreateAccount).to(CreateAccountImpl);
  bind<UpdateAccount>(TYPES.UpdateAccount).to(UpdateAccountImpl);
  bind<GetAccountLoginIdValidation>(TYPES.GetAccountLoginIdValidation).to(GetAccountLoginIdValidationImpl);

  //doctor
  bind<GetDoctors>(TYPES.DOCTOR.GetDoctors).to(GetDoctorsImpl);
  bind<GetDoctor>(TYPES.DOCTOR.GetDoctor).to(GetDoctorImpl);
  bind<AddDoctor>(TYPES.DOCTOR.AddDoctor).to(AddDoctorImpl);
  bind<UpdateDoctor>(TYPES.DOCTOR.UpdateDoctor).to(UpdateDoctorImpl);
  bind<DeleteDoctor>(TYPES.DOCTOR.DeleteDoctor).to(DeleteDoctorImpl);

  bind<DrawConnectorsByMediaPipe>("DrawConnectorsByMediaPipe").to(DrawConnectorsByMediaPipeImpl);

  //face-fit
  bind<CreateConsultingNote>("CreateConsultingNote").to(CreateConsultingNoteImpl);
  bind<UploadConsultingPhoto>("UploadConsultingPhoto").to(UploadConsultingPhotoImpl);
  bind<GetConsultingNote>("GetConsultingNote").to(GetConsultingNoteImpl);
  bind<ReqeustFaceLandmark>("ReqeustFaceLandmark").to(RequestFaceLandmarkImpl);
  bind<GetBestPractices>("GetBestPractices").to(GetBestPracticesImpl);
  bind<UpdateConsultingNote>("UpdateConsultingNote").to(UpdateConsultingNoteImpl);
  bind<GetAIAnalysis>("GetAIAnalysis").to(GetAIAnalysisImpl);
  bind<GetConsultingNotes>("GetConsultingNotes").to(GetConsultingNotesImpl);
  bind<RequestPersonaShift>("RequestPersonaShift").to(RequestPersonaShiftImpl);
  bind<RequestFaceSimilarity>("RequestFaceSimilarity").to(RequestFaceSimilarityImpl);
  bind<GetInfluencerPhotos>("GetInfluencerPhotos").to(GetInfluencerPhotosImpl);
  bind<UpdateBestPracticeExposureStatus>("UpdateBestPracticeExposureStatus").to(UpdateBestPracticeExposureStatusImpl);
  bind<UpdateOperationReservationStatus>("UpdateOperationReservationStatus").to(UpdateOperationReservationStatusImpl);
  bind<GetConsultingNotesByAdvertiseApply>(TYPES.GetConsultingNotesByAdvertiseApply).to(GetConsultingNotesByAdvertiseApplyImpl);
  bind<GetConsultantsByFaceFit>(TYPES.GetConsultantsByFaceFit).to(GetConsultantsByFaceFitImpl);
  bind<GetBestPracticePhotos>(TYPES.GetBestPracticePhotos).to(GetBestPracticePhotosImpl);
  bind<UpdateApplicationStatusByAdvertise>(TYPES.UpdateApplicationStatusByAdvertise).to(UpdateApplicationStatusByAdvertiseImpl);
  bind<UpdateReservationStatusByAdvertise>(TYPES.UpdateReservationStatusByAdvertise).to(UpdateReservationStatusByAdvertiseImpl);
  bind<UpdateReservationDateByAdvertise>(TYPES.UpdateReservationDateByAdvertise).to(UpdateReservationDateByAdvertiseImpl);
  bind<UpdateConsultingStatus>(TYPES.UpdateConsultingStatus).to(UpdateConsultingStatusImpl);
  bind<UpdateReservationStatus>(TYPES.UpdateReservationStatus).to(UpdateReservationStatusImpl);
  bind<UpdateReservationMemoByAdvertise>(TYPES.UpdateReservationMemoByAdvertise).to(UpdateReservationMemoByAdvertiseImpl);
  bind<UpdateConsultingMemo>(TYPES.UpdateConsultingMemo).to(UpdateConsultingMemoImpl);
  bind<UpdateConsultingReservationDate>(TYPES.UpdateConsultingReservationDate).to(UpdateConsultingReservationDateImpl);
  bind<GetTags>(TYPES.GetTags).to(GetTagsImpl);
  bind<GetFaceStyles>(TYPES.FACE_FIT.GetFaceStyles).to(GetFaceStylesImpl);
  bind<GetOperationCategoryGroups>(TYPES.FACE_FIT.GetOperationCategoryGroups).to(GetOperationCategoryGroupsImpl);
  bind<CreateConsultationRecording>(TYPES.FACE_FIT.CreateConsultationRecording).to(CreateConsultationRecordingImpl);
  bind<GetConsultationRecording>(TYPES.FACE_FIT.GetConsultationRecording).to(GetConsultationRecordingImpl);
  bind<CreateConsultingTranscriptions>(TYPES.FACE_FIT.CreateConsultingTranscriptions).to(CreateConsultingTranscriptionsImpl);
  bind<CreateOperationConsultingLog>(TYPES.FACE_FIT.CreateOperationConsultingLog).to(CreateOperationConsultingLogImpl);
  bind<GetConsultationKeywords>(TYPES.FACE_FIT.GetConsultationKeywords).to(GetConsultationKeywordsImpl);
  bind<CreateConsultingTranscriptionAnalysis>(TYPES.FACE_FIT.CreateConsultingTranscriptionAnalysis).to(
    CreateConsultingTranscriptionAnalysisImpl,
  );
  bind<RequestLandmarksForUnextractedPhotos>(TYPES.FACE_FIT.RequestLandmarksForUnextractedPhotos).to(
    RequestLandmarksForUnextractedPhotosImpl,
  );
  bind<CreateBestPractice>(TYPES.FACE_FIT.CreateBestPractice).to(CreateBestPracticeImpl);
  bind<GetBestPractice>(TYPES.FACE_FIT.GetBestPractice).to(GetBestPracticeImpl);
  bind<UpdateBestPractice>(TYPES.FACE_FIT.UpdateBestPractice).to(UpdateBestPracticeImpl);
  bind<DeleteBestPractice>(TYPES.FACE_FIT.DeleteBestPractice).to(DeleteBestPracticeImpl);
  bind<EmbeddingExtractionBestPractice>(TYPES.FACE_FIT.EmbeddingExtractionBestPractice).to(EmbeddingExtractionBestPracticeImpl);
  bind<GetHospitalFaceFitConfiguration>(TYPES.FACE_FIT.GetHospitalFaceFitConfiguration).to(GetHospitalFaceFitConfigurationImpl);
  bind<UpdateHospitalFaceFitConfiguration>(TYPES.FACE_FIT.UpdateHospitalFaceFitConfiguration).to(UpdateHospitalFaceFitConfigurationImpl);
  bind<GetConsultingNoteByNoteNumber>(TYPES.FACE_FIT.GetConsultingNoteByNoteNumber).to(GetConsultingNoteByNoteNumberImpl);

  bind<GetConsultingAnalyticsGroupByConsultant>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByConsultant).to(
    GetConsultingAnalyticsGroupByConsultantImpl,
  );
  bind<GetConsultingAnalyticsGroupByDate>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByDate).to(GetConsultingAnalyticsGroupByDateImpl);
  bind<GetConsultingAnalyticsFirstCreatedDate>(TYPES.ANALYTICS.GetConsultingAnalyticsFirstCreatedDate).to(
    GetConsultingAnalyticsFirstCreatedDateImpl,
  );
  bind<DeleteConsultingNotes>(TYPES.FACE_FIT.DeleteConsultingNotes).to(DeleteConsultingNotesImpl);
  bind<UpdateConsultingNoteConsultant>(TYPES.FACE_FIT.UpdateConsultingNoteConsultant).to(UpdateConsultingNoteConsultantImpl);

  //Invoice
  bind<RejectPaymentApproval>(TYPES.INVOICE.RejectPaymentApproval).to(RejectPaymentApprovalImpl);
  bind<NotifyPaymentErrorMessage>(TYPES.INVOICE.NotifyPaymentErrorMessage).to(NotifyPaymentErrorMessageImpl);
  bind<GetInvoices>(TYPES.INVOICE.GetInvoices).to(GetInvoicesImpl);
  bind<GetInvoicePayment>(TYPES.INVOICE.GetInvoicePayment).to(GetInvoicePaymentImpl);
  bind<RequestPaymentApproval>(TYPES.INVOICE.RequestPaymentApproval).to(RequestPaymentApprovalImpl);

  //gcs
  bind<GetFaceLandmarks>("GetFaceLandmarks").to(GetFaceLandmarksImpl);
  bind<UploadImage>(TYPES.UploadImage).to(UploadImageImpl);
  bind<GetSignedURL>(TYPES.GCS.GetSignedURL).to(GetSignedURLImpl);
});

export default useCases;
