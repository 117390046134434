import { injectable, inject } from "inversify";
import { map, Observable } from "rxjs";
import { UpdateConsultingNoteConsultant, UpdateConsultingNoteConsultantInput } from "./model/UpdateConsultingNoteConsultant";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class UpdateConsultingNoteConsultantImpl implements UpdateConsultingNoteConsultant {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: UpdateConsultingNoteConsultantInput): Observable<void> {
    // return this.operationConsultingRepository.updateConsultingNoteConsultant(input.consultantId , input.consultingNoteId);

    // wㅓㅇ상
    return this.operationConsultingRepository.updateConsultingNoteConsultant(input.consultingNoteId, input.consultantId);
  }
}
