import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { InvoiceRepository } from "@/domain/interactor/repository/InvoiceRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetInvoices, GetInvoicesInput, GetInvoicesOutput } from "./model/GetInvoices";

@injectable()
export class GetInvoicesImpl implements GetInvoices {
  constructor(@inject(TYPES.InvoiceRepository) private readonly invoiceRepository: InvoiceRepository) {}

  execute(input: GetInvoicesInput): Observable<GetInvoicesOutput> {
    return this.invoiceRepository.getInvoices();
  }
}
