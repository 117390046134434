import UISystemContainer from "@/application/ui-system/uiSystem.container";
import * as ViewData from "@/application/view-data";
import { GetConsultingNotesByAdvertiseApply } from "@/domain/usecase/advertisement/model/GetConsultingNotesByAdvertiseApply";
import { UpdateApplicationStatusByAdvertise } from "@/domain/usecase/advertisement/model/UpdateApplicationStatusByAdvertise";
import { UpdateReservationDateByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationDateByAdvertise";
import { UpdateReservationMemoByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationMemoByAdvertise";
import { UpdateReservationStatusByAdvertise } from "@/domain/usecase/advertisement/model/UpdateReservationStatusByAdvertise";
import { GetUserV2 } from "@/domain/usecase/auth/model/GetUserV2";
import { Login } from "@/domain/usecase/auth/model/Login";
import { Logout } from "@/domain/usecase/auth/model/Logout";
import { DrawConnectorsByMediaPipe } from "@/domain/usecase/classification/model/DrawConnectorsByMediaPipe";
import { CreateConsultingNote } from "@/domain/usecase/consulting/model/CreateConsultingNote";
import { GetAIAnalysis } from "@/domain/usecase/consulting/model/GetAIAnalysis";
import { GetBestPracticePhotos } from "@/domain/usecase/consulting/model/GetBestPracticePhotos";
import { GetBestPractices } from "@/domain/usecase/consulting/model/GetBestPractices";
import { GetConsultantsByFaceFit } from "@/domain/usecase/consulting/model/GetConsultantsByFaceFit";
import { GetConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNote";
import { GetConsultingNotes } from "@/domain/usecase/consulting/model/GetConsultingNotes";
import { GetInfluencerPhotos } from "@/domain/usecase/consulting/model/GetInfluencerPhotos";
import { RequestPersonaShift } from "@/domain/usecase/consulting/model/ReqeustPersonaShift";
import { ReqeustFaceLandmark } from "@/domain/usecase/consulting/model/RequestFaceLandmark";
import { RequestFaceSimilarity } from "@/domain/usecase/consulting/model/RequestFaceSimilarity";
import { UpdateBestPracticeExposureStatus } from "@/domain/usecase/consulting/model/UpdateBestPracticeExposureStatus";
import { UpdateConsultingMemo } from "@/domain/usecase/consulting/model/UpdateConsultingMemo";
import { UpdateConsultingNote } from "@/domain/usecase/consulting/model/UpdateConsultingNote";
import { UpdateConsultingReservationDate } from "@/domain/usecase/consulting/model/UpdateConsultingReservationDate";
import { UpdateConsultingStatus } from "@/domain/usecase/consulting/model/UpdateConsultingStatus";
import { UpdateReservationStatus } from "@/domain/usecase/consulting/model/UpdateReservationStatus";
import { UploadConsultingPhoto } from "@/domain/usecase/consulting/model/UploadConsultingPhoto";
import { AddDoctor } from "@/domain/usecase/doctor/model/AddDoctor";
import { DeleteDoctor } from "@/domain/usecase/doctor/model/DeleteDoctor";
import { GetDoctor } from "@/domain/usecase/doctor/model/GetDoctor";
import { GetDoctors } from "@/domain/usecase/doctor/model/GetDoctors";
import { UpdateDoctor } from "@/domain/usecase/doctor/model/UpdateDoctor";
import { GetFaceLandmarks } from "@/domain/usecase/gcs/model/GetFaceLandmarks";
import { UploadImage } from "@/domain/usecase/gcs/model/UploadImage";
import { CreateAccount } from "@/domain/usecase/hospital/model/CreateAccount";
import { GetAccountLoginIdValidation } from "@/domain/usecase/hospital/model/GetAccountLoginIdValidation";
import { GetContract } from "@/domain/usecase/hospital/model/GetContract";
import { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import { GetOperationCategories } from "@/domain/usecase/hospital/model/GetOperationCategories";
import { UpdateAccount } from "@/domain/usecase/hospital/model/UpdateAccount";
import { UpdateHospital } from "@/domain/usecase/hospital/model/UpdateHospital";
import { TYPES } from "@/domain/usecase/index.types";
import { GetNotices } from "@/domain/usecase/notice/model/GetNotices";
import { UserInfo } from "@view-data/User";
import { ContainerModule, interfaces } from "inversify";
import { AccountsViewModelImpl } from "./AccountsViewModelImpl";
import { AdvertiseConsultingCustomersViewModelImpl } from "./AdvertiseConsultingCustomersViewModelImpl";
import { FaceFitViewModelImpl } from "./FaceFitViewModelImpl";
import { AuthorizationViewModelImpl } from "./AuthorizationViewModelImpl";
import { BestPracticeViewModelImpl } from "./BestPracticeViewModelImpl";
import { ContactManagerViewModelImpl } from "./ContactManagerViewModelImpl";
import { CustomersViewModelImpl } from "./CustomersViewModelImpl";
import { DoctorDetailsViewModelImpl } from "./DoctorDetailsViewModelImpl";
import { DoctorsV2ViewModelImpl } from "./DoctorsV2ViewModelImpl";
import { FaceBestPracticesSliderViewModelImpl } from "./FaceBestPracticesSliderViewModelImpl";
import { FaceScanViewModelImpl } from "./FaceScanViewModelImpl";
import { FaceSimilarityFittingViewModelImpl } from "./FaceSimilarityFittingViewModelImpl";
import { GlobalNavigationBarViewModelImpl } from "./GlobalNavigationBarViewModelImpl";
import { HomeViewModelImpl } from "./HomeViewModelImpl";
import { HospitalInformationViewModelImpl } from "./HospitalInformationViewModelImpl";
import { MainViewModelImpl } from "./MainViewModelImpl";
import { AccountsViewModel } from "./model/AccountsViewModel";
import { AdvertiseConsultingCustomersViewModel } from "./model/AdvertiseConsultingCustomersViewModel";
import { FaceFitViewModel } from "./model/FaceFitViewModel";
import { AuthorizationViewModel } from "./model/AuthorizationViewModel";
import { BestPracticeViewModel } from "./model/BestPracticeViewModel";
import { ContactManagerViewModel } from "./model/ContactManagerViewModel";
import { CustomersViewModel } from "./model/CustomersViewModel";
import { DoctorDetailsViewModel } from "./model/DoctorDetailsViewModel";
import { DoctorsV2ViewModel } from "./model/DoctorsV2ViewModel";
import { FaceBestPracticesSliderViewModel } from "./model/FaceBestPracticesSliderViewModel";
import { FaceScanViewModel } from "./model/FaceScanViewModel";
import { FaceSimilarityFittingViewModel } from "./model/FaceSimilarityFittingViewModel";
import { GlobalNavigationBarViewModel } from "./model/GlobalNavigationBarViewModel";
import { HomeViewModel } from "./model/HomeViewModel";
import { HospitalInformationViewModel } from "./model/HospitalInformationViewModel";
import { MainViewModel } from "./model/MainViewModel";
import { PhotoByFaceLandmarkViewModel } from "./model/PhotoByFaceLandmarkViewModel";
import { PlanSettingViewModel } from "./model/PlanSettingViewModel";
import { PhotoByFaceLandmarkViewModelImpl } from "./PhotoByFaceLandmarkViewModelImpl";
import { PlanSettingViewModelImpl } from "./PlanSettingViewModelImpl";
import { FaceShiftViewModel } from "./model/FaceShiftViewModel";
import { FaceShiftViewModelImpl } from "./FaceShiftViewModelImpl";
import { GetTags } from "@/domain/usecase/consulting/model/GetTags";
import { FaceStyleViewModel } from "./model/FaceStyleViewModel";
import { FaceStyleViewModelImpl } from "./FaceStyleViewModelImpl";
import { ImageViewModel } from "./model/ImageViewModel";
import { ImageViewModelImpl } from "./ImageViewModelImpl";
import { GetFaceStyles } from "@/domain/usecase/consulting/model/GetFaceStyles";
import { GetOperationCategoryGroups } from "@/domain/usecase/consulting/model/GetOperationCategoryGroups";
import { GetSignedURL } from "@/domain/usecase/gcs/model/GetSignedURL";
import { CreateConsultationRecording } from "@/domain/usecase/consulting/model/CreateConsultationRecording";
import { GetConsultationRecording } from "@/domain/usecase/consulting/model/GetConsultationRecording";
import { FaceFitNoteDetailsViewModel } from "./model/FaceFitNoteDetailsViewModel";
import { FaceFitNoteDetailsViewModelImpl } from "./FaceFitNoteDetailsViewModelImpl";
import { CreateConsultingTranscriptions } from "@/domain/usecase/consulting/model/CreateConsultingTranscriptions";
import { RecentConsultationsViewModel } from "./model/RecentConsultationsViewModel";
import { RecentConsultationsViewModelImpl } from "./RecentConsultationsViewModelImpl";
import { ConsultationRecordingViewModel } from "./model/ConsultationRecordingViewModel";
import { ConsultationRecordingViewModelImpl } from "./ConsultationRecordingViewModelImpl";
import { CreateOperationConsultingLog } from "@/domain/usecase/consulting/model/CreateOperationConsultingLog";
import { GetConsultationKeywords } from "@/domain/usecase/consulting/model/GetConsultationKeywords";
import { CreateConsultingTranscriptionAnalysis } from "@/domain/usecase/consulting/model/CreateConsultingTranscriptionAnalysis";
import { FaceFitCustomerRegistrationViewModel } from "./model/FaceFitCustomerRegistrationViewModel";
import { FaceFitCustomerRegistrationViewModelImpl } from "./FaceFitCustomerRegistrationViewModelImpl";
import { FaceScanFrontalCanvasViewModel } from "./model/FaceScanFrontalCanvasViewModel";
import { VIEW_MODEL_SYMBOLS } from "./index.types";
import { FaceScanFrontalCanvasViewModelImpl } from "./FaceScanFrontalCanvasViewModelImpl";
import { FaceScanLeft45ViewModel } from "./model/FaceScanLeft45ViewModel";
import { FaceScanLeft45ViewModelImpl } from "./FaceScanLeft45ViewModelImpl";
import { FaceScanCanvasesViewModel } from "./model/FaceScanCanvasesViewModel";
import { FaceScanCanvasesViewModlImpl } from "./FaceScanCanvasesViewModlImpl";
import { RequestLandmarksForUnextractedPhotos } from "@/domain/usecase/consulting/model/RequestLandmarksForUnextractedPhotos";
import { FaceScanRight45ViewModel } from "./model/FaceScanRight45ViewModel";
import { FaceScanRight45ViewModelImpl } from "./FaceScanRight45ViewModelImpl";
import { HospitalAnalyticsViewModel } from "./model/HospitalAnalyticsViewModel";
import { HospitalAnalyticsViewModelImpl } from "./HospitalAnalyticsViewModelImpl";
import { GetConsultingAnalyticsGroupByDate } from "@/domain/usecase/analytics/model/GetConsultingAnalyticsGroupByDate";
import { ConsultantAnalyticsViewModel } from "./model/ConsultantAnalyticsViewModel";
import { ConsultantAnalyticsViewModelImpl } from "./ConsultantAnalyticsViewModelImpl";
import { GetConsultingAnalyticsGroupByConsultant } from "@/domain/usecase/analytics/model/GetConsultingAnalyticsGroupByConsultant";
import { PerformanceAnalyticsViewModel } from "./model/PerformanceAnalyticsViewModel";
import { PerformanceAnalyticsViewModelImpl } from "./PerformanceAnalyticsViewModelImpl";
import { GetConsultingAnalyticsFirstCreatedDate } from "@/domain/usecase/analytics/model/GetConsultingAnalyticsFirstCreatedDate";
import { BestPracticeEditViewModel } from "./model/BestPracticeEditViewModel";
import BestPracticeEditViewModelImpl from "./BestPracticeEditViewModelImpl";
import { GetBestPractice } from "@/domain/usecase/consulting/model/GetBestPractice";
import { UpdateBestPractice } from "@/domain/usecase/consulting/model/UpdateBestPractice";
import { DeleteBestPractice } from "@/domain/usecase/consulting/model/DeleteBestPractice";
import { EmbeddingExtractionBestPractice } from "@/domain/usecase/consulting/model/EmbeddingExtractionBestPractice";
import { BestPracticeAddViewModel } from "./model/BestPracticeAddViewModel";
import BestPracticeAddViewModelImpl from "./BestPracticeAddViewModelImpl";
import { CreateBestPractice } from "@/domain/usecase/consulting/model/CreateBestPractice";
import { GetHospitalFaceFitConfiguration } from "@/domain/usecase/consulting/model/GetHospitalFaceFitConfiguration";
import { UpdateHospitalFaceFitConfiguration } from "@/domain/usecase/consulting/model/UpdateHospitalFaceFitConfiguration";
import { GetConsultingNoteByNoteNumber } from "@/domain/usecase/consulting/model/GetConsultingNotebyNoteNumber";
import { DeleteConsultingNotes } from "@/domain/usecase/consulting/model/DeleteConsultingNotes";
import { UpdateConsultingNoteConsultant } from "@/domain/usecase/consulting/model/UpdateConsultingNoteConsultant";
import { EventManagerTYPES } from "@/event";
import { PaymentOneTimeCheckoutViewModel } from "./model/PaymentOneTimeCheckoutViewModel";
import PaymentOnetimeCheckoutViewModelImpl from "./PaymentOnetimeCheckoutViewModelImpl";
import { PaymentSubscriptionCheckoutViewModel } from "./model/PaymentSubscriptionCheckoutViewModel";
import PaymentSubscriptionCheckoutViewModelImpl from "./PaymentSubscriptionCheckoutViewModelImpl";
import { PaymentOneTimeResultViewModel } from "./model/PaymentOneTimeResultViewModel";
import PaymentOnetimeResultViewModelImpl from "./PaymentOnetimeResultViewModelImpl";
import { PaymentSubscriptionResultViewModel } from "./model/PaymentSubscriptionResultViewModel";
import PaymentSubscriptionResultViewModelImpl from "./PaymentSubscriptionResultViewModelImpl";
import { RejectPaymentApproval } from "@/domain/usecase/invoice/model/RejectPaymentApproval";
import { NotifyPaymentErrorMessage } from "@/domain/usecase/invoice/model/NotifyPaymentErrorMessage";
import { GetInvoices } from "@/domain/usecase/invoice/model/GetInvoices";
import { GetInvoicePayment } from "@/domain/usecase/invoice/model/GetInvoicePayment";
import { RequestPaymentApproval } from "@/domain/usecase/invoice/model/RequestPaymentApproval";

const viewModels = new ContainerModule((bind: interfaces.Bind) => {
  bind<AuthorizationViewModel>("AuthorizationViewModel").toFactory(
    (context) => () => new AuthorizationViewModelImpl(context.container.get<Login>("Login")),
  );
  bind<MainViewModel>("MainViewModel").toFactory(
    (context) => () =>
      new MainViewModelImpl(
        UISystemContainer.instance.UISystem,
        context.container.get<Login>("Login"),
        context.container.get<GetUserV2>("GetUserV2"),
      ),
  );
  bind<interfaces.Factory<GlobalNavigationBarViewModel>>("GlobalNavigationBarViewModel").toFactory(
    (context: interfaces.Context) => (userInfo: UserInfo) =>
      new GlobalNavigationBarViewModelImpl(UISystemContainer.instance.UISystem, userInfo, context.container.get<Logout>("Logout")),
  );
  bind<interfaces.Factory<HomeViewModel>>("HomeViewModel").toFactory(
    (context: interfaces.Context) => (id: number, accountType: string) =>
      new HomeViewModelImpl(id, accountType, context.container.get<GetNotices>("GetNotices")),
  );
  bind<interfaces.Factory<CustomersViewModel>>("CustomersViewModel").toFactory(
    (context: interfaces.Context) => (page: number, size: number, keyword?: string) =>
      new CustomersViewModelImpl(
        page,
        size,
        keyword,
        UISystemContainer.instance.UISystem,
        context.container.get<GetConsultingNotes>("GetConsultingNotes"),
        context.container.get<GetConsultantsByFaceFit>(TYPES.GetConsultantsByFaceFit),
        context.container.get<UpdateConsultingStatus>(TYPES.UpdateConsultingStatus),
        context.container.get<UpdateReservationStatus>(TYPES.UpdateReservationStatus),
        context.container.get<UpdateConsultingMemo>(TYPES.UpdateConsultingMemo),
        context.container.get<UpdateConsultingReservationDate>(TYPES.UpdateConsultingReservationDate),
        context.container.get<GetUserV2>("GetUserV2"),
        context.container.get<DeleteConsultingNotes>(TYPES.FACE_FIT.DeleteConsultingNotes),
        context.container.get<UpdateConsultingNoteConsultant>(TYPES.FACE_FIT.UpdateConsultingNoteConsultant),
      ),
  );

  bind<interfaces.Factory<FaceFitViewModel>>("FaceFitViewModel").toFactory(
    (context: interfaces.Context) => (id?: number) =>
      new FaceFitViewModelImpl(
        id,
        UISystemContainer.instance.UISystem,
        context.container.get<CreateConsultingNote>("CreateConsultingNote"),
        context.container.get<UpdateConsultingNote>("UpdateConsultingNote"),
        context.container.get<UploadConsultingPhoto>("UploadConsultingPhoto"),
        context.container.get<GetConsultingNote>("GetConsultingNote"),
        context.container.get<GetOperationCategories>("GetOperationCategories"),
        context.container.get<GetContract>(TYPES.GetContract),
        context.container.get<GetHospital>(TYPES.GetHospital),
        context.container.get<GetConsultingNoteByNoteNumber>(TYPES.FACE_FIT.GetConsultingNoteByNoteNumber),
      ),
  );
  bind<interfaces.Factory<PhotoByFaceLandmarkViewModel>>("PhotoByFaceLandmarkViewModel").toFactory(
    (context: interfaces.Context) => (landmarkJSONURL: string | undefined) =>
      new PhotoByFaceLandmarkViewModelImpl(
        landmarkJSONURL,
        UISystemContainer.instance.UISystem,
        context.container.get<GetFaceLandmarks>("GetFaceLandmarks"),
        context.container.get<DrawConnectorsByMediaPipe>("DrawConnectorsByMediaPipe"),
      ),
  );
  bind<interfaces.Factory<FaceScanViewModel>>("FaceScanViewModel").toFactory(
    ({ container }) =>
      () =>
        new FaceScanViewModelImpl(UISystemContainer.instance.UISystem),
  );
  bind<interfaces.Factory<BestPracticeViewModel>>(VIEW_MODEL_SYMBOLS.BEST_PRACTICE_VIEW_MODEL).toFactory(
    ({ container }) =>
      (page: number, size: number, subject: string, keyword?: string, gender?: string, categoryIds?: string, tags?: string) =>
        new BestPracticeViewModelImpl(
          page,
          size,
          subject,
          UISystemContainer.instance.UISystem,
          container.get(EventManagerTYPES.EVENT_MANAGER),
          container.get<GetBestPractices>("GetBestPractices"),
          container.get<UpdateBestPracticeExposureStatus>("UpdateBestPracticeExposureStatus"),
          container.get<GetOperationCategories>("GetOperationCategories"),
          container.get<GetHospitalFaceFitConfiguration>(TYPES.FACE_FIT.GetHospitalFaceFitConfiguration),
          container.get<UpdateHospitalFaceFitConfiguration>(TYPES.FACE_FIT.UpdateHospitalFaceFitConfiguration),
          container.get<GetTags>(TYPES.GetTags),
          keyword,
          gender,
          categoryIds,
          tags,
        ),
  );
  bind<interfaces.Factory<HospitalInformationViewModel>>(VIEW_MODEL_SYMBOLS.HOSPITAL_INFORMATION_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new HospitalInformationViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetHospital>(TYPES.GetHospital),
          container.get<UpdateHospital>(TYPES.UpdateHospital),
          container.get<UploadImage>(TYPES.UploadImage),
          container.get<GetUserV2>("GetUserV2"),
        ),
  );
  bind<interfaces.Factory<DoctorsV2ViewModel>>(VIEW_MODEL_SYMBOLS.DOCTORS_V2_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new DoctorsV2ViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetDoctors>(TYPES.DOCTOR.GetDoctors),
          container.get<GetUserV2>("GetUserV2"),
        ),
  );
  bind<interfaces.Factory<DoctorDetailsViewModel>>(VIEW_MODEL_SYMBOLS.DOCTOR_DETAILS_VIEW_MODEL).toFactory(
    ({ container }) =>
      (doctorId: number | null) =>
        new DoctorDetailsViewModelImpl(
          doctorId,
          UISystemContainer.instance.UISystem,
          container.get<GetHospital>(TYPES.GetHospital),
          container.get<GetDoctor>(TYPES.DOCTOR.GetDoctor),
          container.get<AddDoctor>(TYPES.DOCTOR.AddDoctor),
          container.get<UpdateDoctor>(TYPES.DOCTOR.UpdateDoctor),
          container.get<DeleteDoctor>(TYPES.DOCTOR.DeleteDoctor),
          container.get<UploadImage>(TYPES.UploadImage),
        ),
  );
  bind<interfaces.Factory<AdvertiseConsultingCustomersViewModel>>(VIEW_MODEL_SYMBOLS.ADVERTISE_CONSULTING_CUSTOMERS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new AdvertiseConsultingCustomersViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingNotesByAdvertiseApply>(TYPES.GetConsultingNotesByAdvertiseApply),
          container.get<UpdateApplicationStatusByAdvertise>(TYPES.UpdateApplicationStatusByAdvertise),
          container.get<UpdateReservationStatusByAdvertise>(TYPES.UpdateReservationStatusByAdvertise),
          container.get<UpdateReservationDateByAdvertise>(TYPES.UpdateReservationDateByAdvertise),
          container.get<UpdateReservationMemoByAdvertise>(TYPES.UpdateReservationMemoByAdvertise),
        ),
  );
  bind<interfaces.Factory<PlanSettingViewModel>>(VIEW_MODEL_SYMBOLS.PLAN_SETTING_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new PlanSettingViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetContract>(TYPES.GetContract),
          container.get<GetInvoices>(TYPES.INVOICE.GetInvoices),
        ),
  );
  bind<interfaces.Factory<FaceSimilarityFittingViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SIMILARITY_FITTING_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceSimilarityFittingViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetBestPractices>("GetBestPractices"),
          container.get<GetDoctors>(TYPES.DOCTOR.GetDoctors),
          container.get<RequestFaceSimilarity>("RequestFaceSimilarity"),
          container.get<GetAIAnalysis>("GetAIAnalysis"),
          container.get<GetOperationCategories>("GetOperationCategories"),
          container.get<GetTags>(TYPES.GetTags),
          container.get<GetOperationCategoryGroups>(TYPES.FACE_FIT.GetOperationCategoryGroups),
          container.get<GetHospitalFaceFitConfiguration>(TYPES.FACE_FIT.GetHospitalFaceFitConfiguration),
        ),
  );
  bind<interfaces.Factory<FaceBestPracticesSliderViewModel>>(VIEW_MODEL_SYMBOLS.FACE_BEST_PRATICES_SLIDER_VIEW_MODEL).toFactory(
    ({ container }) =>
      (bestPractices: ViewData.Consulting.BeforeAndAfterReview[]) =>
        new FaceBestPracticesSliderViewModelImpl(bestPractices, container.get<GetBestPracticePhotos>(TYPES.GetBestPracticePhotos)),
  );
  bind<interfaces.Factory<AccountsViewModel>>(VIEW_MODEL_SYMBOLS.ACCOUNTS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new AccountsViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetHospital>(TYPES.GetHospital),
          container.get<GetUserV2>("GetUserV2"),
          container.get<CreateAccount>(TYPES.CreateAccount),
          container.get<UpdateAccount>(TYPES.UpdateAccount),
          container.get<GetAccountLoginIdValidation>(TYPES.GetAccountLoginIdValidation),
          container.get<GetContract>(TYPES.GetContract),
        ),
  );
  bind<interfaces.Factory<ContactManagerViewModel>>(VIEW_MODEL_SYMBOLS.CONTACT_MANAGER_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new ContactManagerViewModelImpl(UISystemContainer.instance.UISystem, container.get<GetHospital>(TYPES.GetHospital)),
  );
  bind<interfaces.Factory<FaceShiftViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SHIFT_VIEW_MODEL).toFactory(
    ({ container }) =>
      (note: ViewData.Consulting.Note) =>
        new FaceShiftViewModelImpl(
          note,
          UISystemContainer.instance.UISystem,
          container.get<RequestPersonaShift>("RequestPersonaShift"),
          container.get<GetAIAnalysis>("GetAIAnalysis"),
        ),
  );
  bind<interfaces.Factory<FaceStyleViewModel>>(VIEW_MODEL_SYMBOLS.FACE_STYLE_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceStyleViewModelImpl(UISystemContainer.instance.UISystem, container.get<GetFaceStyles>(TYPES.FACE_FIT.GetFaceStyles)),
  );
  bind<interfaces.Factory<ImageViewModel>>(VIEW_MODEL_SYMBOLS.IMAGE_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new ImageViewModelImpl(container.get<GetSignedURL>(TYPES.GCS.GetSignedURL)),
  );
  bind<interfaces.Factory<FaceFitNoteDetailsViewModel>>(VIEW_MODEL_SYMBOLS.FACE_FIT_NOTE_DETAILS_VIEW_MODEL).toFactory(
    ({ container }) =>
      (noteId: number) =>
        new FaceFitNoteDetailsViewModelImpl(
          noteId,
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingNote>("GetConsultingNote"),
          container.get<UpdateConsultingStatus>(TYPES.UpdateConsultingStatus),
          container.get<UpdateReservationStatus>(TYPES.UpdateReservationStatus),
          container.get<UpdateConsultingMemo>(TYPES.UpdateConsultingMemo),
          container.get<UpdateConsultingReservationDate>(TYPES.UpdateConsultingReservationDate),
          container.get<GetConsultationKeywords>(TYPES.FACE_FIT.GetConsultationKeywords),
          container.get<CreateConsultingTranscriptionAnalysis>(TYPES.FACE_FIT.CreateConsultingTranscriptionAnalysis),
        ),
  );
  bind<interfaces.Factory<RecentConsultationsViewModel>>(VIEW_MODEL_SYMBOLS.RECENT_CONSULTATIONS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new RecentConsultationsViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingNotes>("GetConsultingNotes"),
          container.get<CreateOperationConsultingLog>(TYPES.FACE_FIT.CreateOperationConsultingLog),
        ),
  );
  bind<interfaces.Factory<ConsultationRecordingViewModel>>(VIEW_MODEL_SYMBOLS.CONSULTATION_RECORDING_VIEW_MODEL).toFactory(
    ({ container }) =>
      (noteId: number) =>
        new ConsultationRecordingViewModelImpl(
          noteId,
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingNote>("GetConsultingNote"),
          container.get<CreateConsultationRecording>(TYPES.FACE_FIT.CreateConsultationRecording),
          container.get<CreateConsultingTranscriptions>(TYPES.FACE_FIT.CreateConsultingTranscriptions),
          container.get<CreateOperationConsultingLog>(TYPES.FACE_FIT.CreateOperationConsultingLog),
        ),
  );
  bind<interfaces.Factory<FaceFitCustomerRegistrationViewModel>>(VIEW_MODEL_SYMBOLS.FACE_FIT_CUSTOMER_REGISTRATION_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceFitCustomerRegistrationViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<CreateConsultingNote>("CreateConsultingNote"),
          container.get<UpdateConsultingNote>("UpdateConsultingNote"),
          container.get<UploadConsultingPhoto>("UploadConsultingPhoto"),
          container.get<GetConsultingNote>("GetConsultingNote"),
        ),
  );
  bind<interfaces.Factory<FaceScanFrontalCanvasViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SCAN_FRONTAL_CANVAS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceScanFrontalCanvasViewModelImpl(container.get<GetFaceLandmarks>("GetFaceLandmarks")),
  );
  bind<interfaces.Factory<FaceScanLeft45ViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SCAN_LEFT_45_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceScanLeft45ViewModelImpl(container.get<GetFaceLandmarks>("GetFaceLandmarks")),
  );
  bind<interfaces.Factory<FaceScanCanvasesViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SCAN_CAVASES_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceScanCanvasesViewModlImpl(
          container.get<GetAIAnalysis>("GetAIAnalysis"),
          container.get<ReqeustFaceLandmark>("ReqeustFaceLandmark"),
          container.get<RequestLandmarksForUnextractedPhotos>(TYPES.FACE_FIT.RequestLandmarksForUnextractedPhotos),
        ),
  );
  bind<interfaces.Factory<FaceScanRight45ViewModel>>(VIEW_MODEL_SYMBOLS.FACE_SCAN_RIGHT_45_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new FaceScanRight45ViewModelImpl(container.get<GetFaceLandmarks>("GetFaceLandmarks")),
  );

  bind<interfaces.Factory<HospitalAnalyticsViewModel>>(VIEW_MODEL_SYMBOLS.HOSPITAL_ANALYTICS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new HospitalAnalyticsViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingAnalyticsGroupByDate>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByDate),
        ),
  );

  bind<interfaces.Factory<ConsultantAnalyticsViewModel>>(VIEW_MODEL_SYMBOLS.CONSULTANT_ANALYTICS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new ConsultantAnalyticsViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingAnalyticsGroupByConsultant>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByConsultant),
          container.get<GetConsultingAnalyticsGroupByDate>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByDate),
        ),
  );

  bind<interfaces.Factory<PerformanceAnalyticsViewModel>>(VIEW_MODEL_SYMBOLS.PERFORMANCE_ANALYTICS_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new PerformanceAnalyticsViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<GetConsultingAnalyticsGroupByConsultant>(TYPES.ANALYTICS.GetConsultingAnalyticsGroupByConsultant),
          container.get<GetConsultingAnalyticsFirstCreatedDate>(TYPES.ANALYTICS.GetConsultingAnalyticsFirstCreatedDate),
        ),
  );

  bind<interfaces.Factory<BestPracticeEditViewModel>>(VIEW_MODEL_SYMBOLS.BEST_PRACTICE_EDIT_VIEW_MODEL).toFactory(
    ({ container }) =>
      (bestProfileId: number) =>
        new BestPracticeEditViewModelImpl(
          bestProfileId,
          UISystemContainer.instance.UISystem,
          container.get(EventManagerTYPES.EVENT_MANAGER),
          container.get<GetHospital>(TYPES.GetHospital),
          container.get<UploadImage>(TYPES.UploadImage),
          container.get<GetTags>(TYPES.GetTags),
          container.get<GetOperationCategories>("GetOperationCategories"),
          container.get<GetBestPractice>(TYPES.FACE_FIT.GetBestPractice),
          container.get<UpdateBestPractice>(TYPES.FACE_FIT.UpdateBestPractice),
          container.get<DeleteBestPractice>(TYPES.FACE_FIT.DeleteBestPractice),
          container.get<EmbeddingExtractionBestPractice>(TYPES.FACE_FIT.EmbeddingExtractionBestPractice),
        ),
  );

  bind<interfaces.Factory<BestPracticeAddViewModel>>(VIEW_MODEL_SYMBOLS.BEST_PRACTICE_ADD_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new BestPracticeAddViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get(EventManagerTYPES.EVENT_MANAGER),
          container.get<GetHospital>(TYPES.GetHospital),
          container.get<UploadImage>(TYPES.UploadImage),
          container.get<GetTags>(TYPES.GetTags),
          container.get<GetOperationCategories>("GetOperationCategories"),
          container.get<CreateBestPractice>(TYPES.FACE_FIT.CreateBestPractice),
          container.get<EmbeddingExtractionBestPractice>(TYPES.FACE_FIT.EmbeddingExtractionBestPractice),
        ),
  );

  bind<interfaces.Factory<PaymentOneTimeCheckoutViewModel>>(VIEW_MODEL_SYMBOLS.PAYMENT_ONETIME_CHECKOUT_VIEW_MODEL).toFactory(
    ({ container }) =>
      (orderId: string) =>
        new PaymentOnetimeCheckoutViewModelImpl(
          orderId,
          UISystemContainer.instance.UISystem,
          container.get<GetInvoicePayment>(TYPES.INVOICE.GetInvoicePayment),
        ),
  );

  bind<interfaces.Factory<PaymentOneTimeResultViewModel>>(VIEW_MODEL_SYMBOLS.PAYMENT_ONETIME_RESULT_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new PaymentOnetimeResultViewModelImpl(
          UISystemContainer.instance.UISystem,
          container.get<RequestPaymentApproval>(TYPES.INVOICE.RequestPaymentApproval),
          container.get<RejectPaymentApproval>(TYPES.INVOICE.RejectPaymentApproval),
          container.get<NotifyPaymentErrorMessage>(TYPES.INVOICE.NotifyPaymentErrorMessage),
        ),
  );

  bind<interfaces.Factory<PaymentSubscriptionCheckoutViewModel>>(VIEW_MODEL_SYMBOLS.PAYMENT_SUBSCRIPTION_CHECKOUT_VIEW_MODEL).toFactory(
    ({ container }) =>
      (orderId: string) =>
        new PaymentSubscriptionCheckoutViewModelImpl(orderId, UISystemContainer.instance.UISystem),
  );

  bind<interfaces.Factory<PaymentSubscriptionResultViewModel>>(VIEW_MODEL_SYMBOLS.PAYMENT_SUBSCRIPTION_RESULT_VIEW_MODEL).toFactory(
    ({ container }) =>
      () =>
        new PaymentSubscriptionResultViewModelImpl(UISystemContainer.instance.UISystem),
  );
});

export default viewModels;
