export const TYPES = {
  AdRepository: Symbol.for("AdRepository"),
  DoctorRepository: Symbol.for("DoctorRepository"),
  FeedbackRepository: Symbol.for("FeedbackRepository"),
  HospitalRepository: Symbol.for("HospitalRepository"),
  LocalRepository: Symbol.for("LocalRepository"),
  NoticeRepository: Symbol.for("NoticeRepository"),
  OperationConsultingRepository: Symbol.for("OperationConsultingRepository"),
  SessionRepository: Symbol.for("SessionRepository"),
  TokenRepository: Symbol.for("TokenRepository"),
  UserRepository: Symbol.for("UserRepository"),
  AnalyticsRepository: Symbol.for("AnalyticsRepository"),
  InvoiceRepository: Symbol.for("InvoiceRepository")
};
