import { FC } from "react";
import IconImpl, { IconTypes, ICON_COLOR_TYPE } from "./IconImpl";

interface IconHocProps {
  xxsmall?: boolean;
  xsmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xlarge?: boolean;
  xxlarge?: boolean;
  xxxlarge?: boolean;
  _36px?: boolean;
  _18px?: boolean;
  _16px?: boolean;
  _14px?: boolean;
  _12px?: boolean;
  fill?: boolean;
  selected?: boolean;
  active?: boolean;
  hover?: boolean;
  color?: ICON_COLOR_TYPE;
  colorCode?: string;
  activeColor?: string;
  hoverColor?: string;
  onClick?: () => void;
}

const IconHOC = (iconType: IconTypes) => {
  const Inner: React.FC<IconHocProps> = (props) => {
    const size = {
      xxxlarge: 48,
      xxlarge: 40,
      _36px: 36,
      xlarge: 32,
      large: 28,
      medium: 24,
      small: 20,
      _18px: 18,
      _16px: 16,
      _14px: 14,
      _12px: 12,
      xsmall: 16,
      xxsmall: 12,
    };

    const setSize = () => {
      if (props.xxxlarge) {
        return size.xxxlarge;
      } else if (props.xxlarge) {
        return size.xxlarge;
      } else if (props.xlarge) {
        return size.xlarge;
      } else if (props.large) {
        return size.large;
      } else if (props.small) {
        return size.small;
      } else if (props.xsmall) {
        return size.xsmall;
      } else if (props.xxsmall) {
        return size.xxsmall;
      } else if (props._18px) {
        return size._18px;
      } else if (props._16px) {
        return size._16px;
      } else if (props._14px) {
        return size._14px;
      } else if (props._12px) {
        return size._12px;
      } else if (props._36px) {
        return size._36px;
      } else {
        return size.medium;
      }
    };

    const setHoverColor = (color: string) => {
      return props.hover ? color : undefined;
    };

    const setActiveColor = (color: string) => {
      return props.active ? color : undefined;
    };

    return (
      <IconImpl
        name={iconType}
        color={props.selected ? "semanticActive" : props.selected === false ? "neutralGray400" : props.color ? props.color : undefined}
        colorCode={props.colorCode}
        size={setSize()}
        hoverColor={setHoverColor(props.hoverColor || "neutralGray400")}
        activeColor={setActiveColor(props.activeColor || "neutralGray200")}
        onClick={props.onClick}
      />
    );
  };

  return Inner;
};

const Icon: Record<IconTypes, FC<IconHocProps>> = {
  AdCheck: IconHOC("AdCheck"),
  AdList: IconHOC("AdList"),
  Add: IconHOC("Add"),
  Admin: IconHOC("Admin"),
  ArrowClose: IconHOC("ArrowClose"),
  ArrowOpen: IconHOC("ArrowOpen"),
  AddImage: IconHOC("AddImage"),
  ArrowLeftSLine: IconHOC("ArrowLeftSLine"),
  ArrowRightSLine: IconHOC("ArrowRightSLine"),
  ArrowZoom: IconHOC("ArrowZoom"),
  Calendar: IconHOC("Calendar"),
  Cancel: IconHOC("Cancel"),
  CheckBlack: IconHOC("CheckBlack"),
  CheckWhite: IconHOC("CheckWhite"),
  CheckLine: IconHOC("CheckLine"),
  CloseLine: IconHOC("CloseLine"),
  CloseCircleFill: IconHOC("CloseCircleFill"),
  Close: IconHOC("Close"),
  Coin: IconHOC("Coin"),
  Database2Line: IconHOC("Database2Line"),
  DB: IconHOC("DB"),
  DraftLine: IconHOC("DraftLine"),
  Group: IconHOC("Group"),
  HospitalLine: IconHOC("HospitalLine"),
  Hospital: IconHOC("Hospital"),
  Info20Line: IconHOC("Info20Line"),
  LineChartLine: IconHOC("LineChartLine"),
  List: IconHOC("List"),
  LockPasswordLine: IconHOC("LockPasswordLine"),
  Logout: IconHOC("Logout"),
  Message2Line: IconHOC("Message2Line"),
  Minimize: IconHOC("Minimize"),
  PhoneLine: IconHOC("PhoneLine"),
  Preview: IconHOC("Preview"),
  ProfileLine: IconHOC("ProfileLine"),
  Register: IconHOC("Register"),
  Remove: IconHOC("Remove"),
  Reset: IconHOC("Reset"),
  ViewImage: IconHOC("ViewImage"),
  Save: IconHOC("Save"),
  Search: IconHOC("Search"),
  ShieldCheckLine: IconHOC("ShieldCheckLine"),
  Stay: IconHOC("Stay"),
  StethoscopeLine: IconHOC("StethoscopeLine"),
  TimeLine: IconHOC("TimeLine"),
  Upload16Line: IconHOC("Upload16Line"),
  HomeMail: IconHOC("HomeMail"),
  HomeKakao: IconHOC("HomeKakao"),
  UserSettingLine: IconHOC("UserSettingLine"),
  EmpathizeLine: IconHOC("EmpathizeLine"),
  EditPencil: IconHOC("EditPencil"),
  VisibilityOff: IconHOC("VisibilityOff"),
  CheckGreen: IconHOC("CheckGreen"),
  PreviewWhite: IconHOC("PreviewWhite"),
  Empty: IconHOC("Empty"),
  Kakao: IconHOC("Kakao"),
  MenuLine: IconHOC("MenuLine"),
  ArrowLeftLine: IconHOC("ArrowLeftLine"),
  LogoutCircleRLine: IconHOC("LogoutCircleRLine"),
  CancelUnion: IconHOC("CancelUnion"),
  Notice: IconHOC("Notice"),
  FailRed: IconHOC("FailRed"),
  Attachment: IconHOC("Attachment"),
  ExclamationMark: IconHOC("ExclamationMark"),
  QuestionLine: IconHOC("QuestionLine"),
  Edit: IconHOC("Edit"),
  UploadImage: IconHOC("UploadImage"),
  Plus: IconHOC("Plus"),
  Visibility: IconHOC("Visibility"),
  FaceOnOff: IconHOC("FaceOnOff"),
  AddOutlined: IconHOC("AddOutlined"),
  FilledCheckBox: IconHOC("FilledCheckBox"),
  OutlinedCheck: IconHOC("OutlinedCheck"),
  MenuAccount: IconHOC("MenuAccount"),
  MenuAdCustomers: IconHOC("MenuAdCustomers"),
  MenuContacts: IconHOC("MenuContacts"),
  MenuDoctors: IconHOC("MenuDoctors"),
  MenuFaceFitCustomers: IconHOC("MenuFaceFitCustomers"),
  MenuInformation: IconHOC("MenuInformation"),
  MenuPlan: IconHOC("MenuPlan"),
  MenuExpand: IconHOC("MenuExpand"),
  MenuCollpase: IconHOC("MenuCollpase"),
  OutlinedSearch: IconHOC("OutlinedSearch"),
  OutlinedReset: IconHOC("OutlinedReset"),
  GlobalNavigationServiceCenter: IconHOC("GlobalNavigationServiceCenter"),
  OutlinedLinedArrowBack: IconHOC("OutlinedLinedArrowBack"),
  FilledCircleCheck: IconHOC("FilledCircleCheck"),
  OutlinedPencil: IconHOC("OutlinedPencil"),
  OutlinedTrash: IconHOC("OutlinedTrash"),
  FilledCamera: IconHOC("FilledCamera"),
  FilledFolder: IconHOC("FilledFolder"),
  OutlinedStyleFit: IconHOC("OutlinedStyleFit"),
  FilledRecording: IconHOC("FilledRecording"),
  OutlinedStop: IconHOC("OutlinedStop"),
  FilledPlay: IconHOC("FilledPlay"),
  FilledPause: IconHOC("FilledPause"),
  OutlinedDownload: IconHOC("OutlinedDownload"),
  RoundedArrowIOS: IconHOC("RoundedArrowIOS"),
  RoundedClose: IconHOC("RoundedClose"),
  FilledArrow: IconHOC("FilledArrow"),
  FilledArrowUp: IconHOC("FilledArrowUp"),
  FilledRuler: IconHOC("FilledRuler"),
  FilledArrowLeft: IconHOC("FilledArrowLeft"),
  FilledChart: IconHOC("FilledChart"),
  FilledInformation: IconHOC("FilledInformation")
};

export default Icon;
