import { Container } from "inversify";
import application from "./application";
import domain from "./domain";
import data from "./data";
import { Classification } from "@/classification/index.container";
import { EventManagerContainer } from "@/event/index.container";

const containerInstance = new Container();
containerInstance.load(Classification, EventManagerContainer);

const container = Container.merge(application, domain, data, containerInstance);

export default container;
