export type EventName = "embedSuccessEvent";

export interface EventManager {
  add: (eventName: EventName, handler: EventListenerOrEventListenerObject) => void;
  remove: (eventName: EventName, handler: EventListenerOrEventListenerObject) => void;
  dispatch: (eventName: EventName, detail?: any) => void;
}

export const EventManagerTYPES = {
  EVENT_MANAGER: Symbol.for("EventManager"),
};
