import { inject, injectable } from "inversify";
import * as VO from "@/domain/interactor/repository/vo";
import { map, Observable } from "rxjs";
import { InvoiceRepository } from "@/domain/interactor/repository/InvoiceRepository";
import type { InvoiceAPI } from "@/data/remote/api/invoice/model/InvoiceAPI";
import { TYPES } from "@/data/remote/api/index.container.type";
import Utils from "./Utils";

@injectable()
export class InvoiceRepositoryImpl implements InvoiceRepository {
  constructor(@inject(TYPES.InvoiceAPI) private readonly invoiceAPI: InvoiceAPI) {}

  getPaymentDetail = (invoiceKey: string): Observable<VO.Invoice.PaymentDetail> => {
    return this.invoiceAPI.getPaymentDetail({
      invoiceKey: invoiceKey,
    });
  };

  getInvoices = (): Observable<{ items: VO.Invoice.Invoice[] }> => {
    return this.invoiceAPI.getInvoices().pipe(
      map((res) => ({
        items: res.items.map((item) => ({
          ...item,
          createdAt: Utils.convertDateStringToLocalDate(item.createdAt),
          discardedAt: item.discardedAt ? Utils.convertDateStringToLocalDate(item.discardedAt) : null,
          payment: {
            ...item.payment,
            approvedAt: item.payment.approvedAt ? Utils.convertDateStringToLocalDate(item.payment.approvedAt) : null,
            rejectedAt: item.payment.rejectedAt ? Utils.convertDateStringToLocalDate(item.payment.rejectedAt) : null,
            type: "ONE_TIME",
          },
        })),
      })),
    );
  };

  confirmPaymentApproval = (
    invoiceKey: string,
    paymentInfo: VO.Invoice.PaymentApprovalInfo,
  ): Observable<{ invoiceId: number; invoiceKey: string }> => {
    return this.invoiceAPI.confirmPaymentApproval({
      invoiceKey,
      paymentInfo,
    });
  };

  rejectPaymentApproval = (
    invoiceKey: string,
    paymentInfo: VO.Invoice.PaymentRejectInfo,
  ): Observable<{ invoiceId: number; invoiceKey: string }> => {
    return this.invoiceAPI.rejectPaymentApproval({
      invoiceKey,
      ...paymentInfo,
    });
  };
}
