import { inject, injectable } from "inversify";
import { RejectPaymentApproval, RejectPaymentApprovalInput, RejectPaymentApprovalOutput } from "./model/RejectPaymentApproval";

import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { InvoiceRepository } from "@/domain/interactor/repository/InvoiceRepository";
import { Observable } from "rxjs";

@injectable()
export class RejectPaymentApprovalImpl implements RejectPaymentApproval {
  constructor(@inject(TYPES.InvoiceRepository) private readonly invoiceRepository: InvoiceRepository) {}

  execute(input: RejectPaymentApprovalInput): Observable<RejectPaymentApprovalOutput> {
    return this.invoiceRepository.rejectPaymentApproval(input.invoiceKey, {
      paymentGateType: input.paymentGateType,
      errorCode: input.errorCode,
      errorMessage: input.errorMessage,
    });
  }
}
