import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import { Config, Coordinate } from "../../index.interface";
import Calc from "../../utils/Calc";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "@view-model/face-classification/utils/Styles";

interface Coordinates {
  noseWidth: {
    right: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
    left: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
  };
  noseBridge: {
    right: Coordinate[];
    left: Coordinate[];
  };
}

export class NoseBridgeWidthRatio45 extends DrawingFacialLandmarks {
  private coordinate: Coordinates;
  ratio: {
    right: number;
    center: number;
    left: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);
    this.coordinate = this.getCoordinates();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async (ratio: { right: number; center: number; left: number }) => {
    this.drawPhotoBaseDrawer.drawNoseLines();
    await this.delay(300);
    this.drawPhotoBaseDrawer.drawRatioText(ratio);
    await this.delay(300);
    this.drawPhotoBaseDrawer.drawGuideLine();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText();
    await this.delay(300);
    this.bestRatioDrawer.drawGuideLine();
  };

  private drawPhotoBaseDrawer = {
    drawNoseLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.right.bottom,
        point2: this.coordinate.noseWidth.left.right.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.left.bottom,
        point2: this.coordinate.noseWidth.left.left.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.left.bottom,
        point2: this.coordinate.noseWidth.right.left.top,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      // await this.delay(300);
      // this.drawLineByCoordinates(this.coordinate.noseBridge.left, { ...DotLineStyle, lineColor: "#F900FE" }, true);
    },
    drawRatioText: async (ratio: { right: number; center: number; left: number }) => {
      const textStyle = { x: -28, y: -15, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${(ratio.right * 10).toFixed(2)}`, this.faceCoordinates[18], this.faceCoordinates[18], {
        ...textStyle,
        x: textStyle.x - 60,
      });
      this.drawTextBetweenPoints(`${(ratio.center * 10).toFixed(2)}`, this.faceCoordinates[18], this.faceCoordinates[18], textStyle);
      this.drawTextBetweenPoints(`${(ratio.left * 10).toFixed(2)}`, this.faceCoordinates[18], this.faceCoordinates[18], {
        ...textStyle,
        x: textStyle.x + 60,
      });
    },
    drawGuideLine: async () => {
      const yPoint = this.coordinate.noseWidth.right.right.bottom.y - 15;
      const right = { ...this.coordinate.noseWidth.right.left.bottom, y: yPoint };
      const center = { ...this.coordinate.noseWidth.left.left.bottom, y: yPoint };

      this.drawLineBetweenPoints({ point1: right, point2: center, lineStyle: DotLineStyle, isDrawEndPoint: false });
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: async () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.right.bottom,
        point2: this.coordinate.noseWidth.left.right.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.left.left.bottom,
        point2: this.coordinate.noseWidth.left.left.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.noseWidth.right.left.bottom,
        point2: this.coordinate.noseWidth.right.left.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      await this.delay(300);
      this.drawLineByCoordinates(this.coordinate.noseBridge.left, { ...DotLineStyle, lineColor: "#F900FE" }, true);
    },
    drawRatioText: async () => {
      const textStyle = { x: -28, y: 10, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`1.00`, this.faceCoordinates[18], this.faceCoordinates[18], {
        ...textStyle,
        x: textStyle.x - 60,
      });
      this.drawTextBetweenPoints(`8.00`, this.faceCoordinates[18], this.faceCoordinates[18], textStyle);
      this.drawTextBetweenPoints(`1,00`, this.faceCoordinates[18], this.faceCoordinates[18], {
        ...textStyle,
        x: textStyle.x + 60,
      });
    },
    drawGuideLine: async () => {
      const yPoint = this.coordinate.noseWidth.right.right.bottom.y - 15;
      const right = { ...this.coordinate.noseWidth.right.left.bottom, y: yPoint };
      const center = { ...this.coordinate.noseWidth.left.left.bottom, y: yPoint };

      this.drawLineBetweenPoints({ point1: right, point2: center, lineStyle: DotLineStyle, isDrawEndPoint: false });
    },
  };

  private getCoordinates = (): Coordinates => {
    const bottomYPoint = this.faceCoordinates[2].y + 25;
    const middleYPoint = this.faceCoordinates[4].y;
    const topYPoint = this.faceCoordinates[55].y;

    return {
      noseWidth: {
        right: {
          right: {
            top: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: middleYPoint },
            bottom: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: bottomYPoint },
          },
          left: {
            top: { ...this.faceCoordinates[55], y: topYPoint },
            bottom: { ...this.faceCoordinates[55], y: bottomYPoint },
          },
        },
        left: {
          right: {
            top: { ...this.faceCoordinates[285], y: topYPoint },
            bottom: { ...this.faceCoordinates[285], y: bottomYPoint },
          },
          left: {
            top: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: this.faceCoordinates[252].y },
            bottom: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: bottomYPoint },
          },
        },
      },
      noseBridge: {
        right: [
          this.faceCoordinates[55],
          this.faceCoordinates[193],
          this.faceCoordinates[122],
          this.faceCoordinates[196],
          this.faceCoordinates[3],
          this.faceCoordinates[51],
          this.faceCoordinates[220],
        ],
        left: [
          this.faceCoordinates[285],
          this.faceCoordinates[417],
          this.faceCoordinates[351],
          this.faceCoordinates[419],
          this.faceCoordinates[248],
          this.faceCoordinates[281],
          this.faceCoordinates[440],
        ],
      },
    };
  };

  private calcRatio = () => {
    const rightLength = Calc.calculateDistance(
      this.coordinate.noseWidth.right.right.bottom,
      this.coordinate.noseWidth.right.left.bottom,
      "2d",
    );

    const middleLength = Calc.calculateDistance(
      this.coordinate.noseWidth.right.left.bottom,
      this.coordinate.noseWidth.left.right.bottom,
      "2d",
    );

    const leftLength = Calc.calculateDistance(
      this.coordinate.noseWidth.left.right.bottom,
      this.coordinate.noseWidth.left.left.bottom,
      "2d",
    );

    const totalLength = rightLength + middleLength + leftLength;

    return {
      right: Calc.calcRatio(totalLength, rightLength),
      center: Calc.calcRatio(totalLength, middleLength),
      left: Calc.calcRatio(totalLength, leftLength),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
