import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { injectable } from "inversify";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { PaymentDetail } from "@view-data/Payment";
import { PaymentOneTimeCheckoutViewModel } from "./model/PaymentOneTimeCheckoutViewModel";
import type { GetInvoicePayment } from "@/domain/usecase/invoice/model/GetInvoicePayment";

@injectable()
export default class PaymentOnetimeCheckoutViewModelImpl implements PaymentOneTimeCheckoutViewModel {
  private clientKey = process.env.TOSS_PAYMENT_WIDGET_CLIENT_KEY ?? "";

  data: PaymentOneTimeCheckoutViewModel["data"] = {};
  input: PaymentOneTimeCheckoutViewModel["input"] = {};
  output: PaymentOneTimeCheckoutViewModel["output"] = {
    orderData: new BehaviorSubject<PaymentDetail | null>(null),
  };
  route: PaymentOneTimeCheckoutViewModel["route"] = {};

  constructor(invoiceKey: string, readonly uiSystem: UISystemManager, private readonly ucGetInvoicePayment: GetInvoicePayment) {
    this.init(invoiceKey);
  }

  private init = (invoiceKey: string) => {
    this.ucGetInvoicePayment
      .execute({
        invoiceKey: invoiceKey,
      })
      .subscribe({
        next: (orderData) => {
          if (orderData) {
            this.output.orderData.next({ ...orderData, clientKey: this.clientKey });
          }
        },
      });
  };
}
