import { LandmarkPoint, PointAndCoordinate } from "../index.interface";
import { ConnectionPoint, Coordinate, DenormalizedCoordinate } from "../index.interface";

class Calc {
  getDenormalizedFaceLandmark(
    imageEle: HTMLImageElement,
    coordinates: Coordinate[][],
    landmarkConnections: ConnectionPoint[],
  ): DenormalizedCoordinate {
    const list: number[] = [];

    landmarkConnections.forEach((connection) => {
      if (!list.includes(connection.start)) {
        list.push(connection.start);
      }

      if (!list.includes(connection.end)) {
        list.push(connection.end);
      }
    });

    if (coordinates[0]) {
      const normalizedLandmarkList = list.map((item) => {
        return { point: item, mark: coordinates[0][item] };
      });

      const coordinate: DenormalizedCoordinate = {};

      normalizedLandmarkList.forEach((result) => {
        const point = result.point;
        const landmark = result.mark;
        coordinate[point] = {
          x: imageEle.width * Number(landmark.x),
          y: imageEle.height * Number(landmark.y),
          z: imageEle.width * Number(landmark.z),
        };
      });

      return coordinate;
    } else {
      return {};
    }
  }

  getDenormalizedCoordinates = (
    imageEle: HTMLImageElement,
    coordinates: Coordinate[][],
    landmarkPoints: LandmarkPoint[],
  ): DenormalizedCoordinate => {
    if (!coordinates[0]) {
      return {};
    }

    const denormalizedCoordinate: DenormalizedCoordinate = {};

    landmarkPoints.forEach((point) => {
      const landmark = coordinates[0][point];
      denormalizedCoordinate[point] = {
        x: imageEle.width * Number(landmark.x),
        y: imageEle.height * Number(landmark.y),
        z: imageEle.width * Number(landmark.z),
      };
    });

    return denormalizedCoordinate;
  };

  findRightAngleTrianglePoint = (type: "LEFT" | "RIGHT", p1: Coordinate, p2: Coordinate): Coordinate => {
    switch (type) {
      case "LEFT":
        return {
          x: p1?.x ?? 0,
          y: p2?.y ?? 0,
          z: p1?.z ?? 0,
        };

      case "RIGHT":
        return {
          x: p2?.x ?? 0,
          y: p1?.y ?? 0,
          z: p1?.z ?? 0,
        };
    }
  };

  findIntersectionPoint = (value: {
    lineA: { pointA: Coordinate; pointB: Coordinate };
    lineB: { pointA: Coordinate; pointB: Coordinate };
  }): Coordinate | null => {
    const { pointA: A1, pointB: A2 } = value.lineA;
    const { pointA: B1, pointB: B2 } = value.lineB;

    const denominator = (A1.x - A2.x) * (B1.y - B2.y) - (A1.y - A2.y) * (B1.x - B2.x);

    if (denominator === 0) {
      return null;
    }

    const t = ((A1.x - B1.x) * (B1.y - B2.y) - (A1.y - B1.y) * (B1.x - B2.x)) / denominator;

    const u = -(((A1.x - A2.x) * (A1.y - B1.y) - (A1.y - A2.y) * (A1.x - B1.x)) / denominator);

    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      const intersectionX = A1.x + t * (A2.x - A1.x);
      const intersectionY = A1.y + t * (A2.y - A1.y);
      return { x: intersectionX, y: intersectionY, z: 0 };
    }

    return null;
  };

  truncateDecimals(number: number, digits: number) {
    const multiplier = Math.pow(10, digits);
    return Math.floor(number * multiplier) / multiplier;
  }

  calcAngle({ point1, center, point2 }: { point1: Coordinate; center: Coordinate; point2: Coordinate }, type: "3d" | "2d"): number {
    const pointA = type === "3d" ? point1 : { ...point1, z: 0 };
    const pointB = type === "3d" ? center : { ...center, z: 0 };
    const pointC = type === "3d" ? point2 : { ...point2, z: 0 };

    const vectorAB = this.calculateVector(pointB, pointA);
    const vectorBC = this.calculateVector(pointB, pointC);

    const dotProduct = this.calculateDot(vectorAB, vectorBC);
    const magnitudeAB = this.calculateMagnitude(vectorAB);
    const magnitudeBC = this.calculateMagnitude(vectorBC);

    let cosTheta = dotProduct / (magnitudeAB * magnitudeBC);
    cosTheta = Math.min(Math.max(cosTheta, -1), 1);

    const angleRad = Math.acos(cosTheta);
    const angleDeg = (angleRad * 180) / Math.PI;

    return parseFloat(angleDeg.toFixed(2)); // 소수점 둘째 자리까지 반환
  }

  calculateAngleWithVectors = (point: { pointA: Coordinate; pointB: Coordinate }): number => {
    const dx = point.pointB.x - point.pointA.x;
    const dy = point.pointB.y - point.pointA.y;
    let angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    return angle;
  };

  getCoordinateByAngle = (pointA: Coordinate, pointB: Coordinate, angle: number, direction: "RIGHT" | "LEFT"): Coordinate => {
    const dx = pointB.x - pointA.x;
    const dy = pointB.y - pointA.y;

    // AB 선분의 길이
    const distanceAB = Math.sqrt(dx * dx + dy * dy);

    // A, B, C 삼각형에서 B 기준으로 C까지의 거리 설정 (AB의 길이를 사용)
    const distanceBC = distanceAB;

    // AB 선분의 기존 각도
    const baseAngle = Math.atan2(dy, dx);

    // ∠ABC를 보각으로 변환 (내각을 외각으로 변경)
    const angleToRotate = 180 - angle; // 110° => 70° (외각)

    // 각도를 라디안으로 변환
    const angleInRadians = (angleToRotate * Math.PI) / 180;

    // 방향에 따라 C의 각도 조정
    const adjustedAngle = direction === "RIGHT" ? baseAngle - angleInRadians : baseAngle + angleInRadians;

    // C 좌표 계산
    const newX = pointB.x + distanceBC * Math.cos(adjustedAngle);
    const newY = pointB.y + distanceBC * Math.sin(adjustedAngle);

    return {
      x: newX,
      y: newY,
      z: pointB.z || 0,
    };
  };

  getSideFaceSideCoordinate = (imageEle: HTMLImageElement, coordinates: { [key: number]: Coordinate }) => {
    // 이미지 중앙 좌표 계산
    const imageCenterX = imageEle.width / 2;

    // x와 z 값을 교환하고 z 값을 양수로 변환한 뒤, x 값을 이미지 중앙으로 이동
    const transformedCoordinates = Object.keys(coordinates).reduce((acc, key) => {
      const coord = coordinates[parseInt(key)];
      acc[parseInt(key)] = {
        x: (coord.z ?? 1) * -1 + imageCenterX, // x 값을 이미지 중앙으로 이동
        y: coord.y,
        z: coord.x,
      };
      return acc;
    }, {} as PointAndCoordinate);

    return transformedCoordinates;
  };

  // 두 점 사이의 거리를 계산하는 함수
  calculateDistance(pointA: Coordinate, pointB: Coordinate, type: "3d" | "2d") {
    const point1 = type === "3d" ? pointA : { ...pointA, z: 0 };
    const point2 = type === "3d" ? pointB : { ...pointB, z: 0 };

    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    const dz = type === "3d" ? (point2?.z ?? 0) - (point1?.z ?? 0) : 0;

    // 유클리드 거리 계산
    const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);

    return distance;
  }

  //기준값 대비 비율 (소수점 둘째자리까지만 표현)
  calcRatio = (baseValue: number, compareValue: number): number => {
    if (baseValue === 0) {
      throw new Error("baseValue는 0이 입력 될 수 없습니다.");
    }

    return parseFloat((compareValue / baseValue).toFixed(2));
  };

  calculateCoordinateByAngleAndDirection = (
    baseCoordinate: Coordinate,
    angle: number,
    length: number,
    direction: "TOP_RIGHT" | "TOP_LEFT",
  ): Coordinate => {
    const calcedAngle = (Math.abs(angle) * Math.PI) / 180;

    if (direction === "TOP_RIGHT") {
      const newX = baseCoordinate.x - length * Math.cos(calcedAngle);
      const newY = baseCoordinate.y - length * Math.sin(calcedAngle);

      return { x: newX, y: newY, z: baseCoordinate.z || 0 };
    } else {
      const newX = baseCoordinate.x + length * Math.cos(calcedAngle);
      const newY = baseCoordinate.y - length * Math.sin(calcedAngle);

      return { x: newX, y: newY, z: baseCoordinate.z || 0 };
    }
  };

  //기울기가 생기는 두 좌표
  getCoordinateByInclination = ({
    point1,
    point2,
    length,
    direction,
  }: {
    point1: Coordinate;
    point2: Coordinate;
    length: number;
    direction: "LEFT" | "RIGHT";
  }): Coordinate => {
    // 두 점 사이의 기울기 계산
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;

    // 두 점이 수평선 상에 있는 경우 (dy = 0)
    if (dy === 0) {
      return {
        x: direction === "LEFT" ? point2.x - length : point2.x + length,
        y: point2.y,
        z: 0,
      };
    }

    // 두 점이 수직선 상에 있는 경우 (dx = 0)
    if (dx === 0) {
      return {
        x: point2.x,
        y: direction === "LEFT" ? point2.y + length : point2.y - length,
        z: 0,
      };
    }

    // 두 점 사이의 기울기 (slope)
    const slope = dy / dx;

    // 방향에 따라 이동할 각도 계산
    const angle = Math.atan(slope);

    // 방향에 따라 이동한 새로운 좌표 계산
    const deltaX = length * Math.cos(angle);
    const deltaY = length * Math.sin(angle);

    const newX = direction === "LEFT" ? point2.x - deltaX : point2.x + deltaX;
    const newY = direction === "LEFT" ? point2.y - deltaY : point2.y + deltaY;

    return {
      x: newX,
      y: newY,
      z: 0,
    };
  };

  pFloat = (value: number) => {
    return parseFloat(value.toFixed(2)).toString();
  };

  // 벡터를 계산하는 함수
  private calculateVector(point1: Coordinate, point2: Coordinate) {
    const vector = {
      x: point2.x - point1.x,
      y: point2.y - point1.y,
      z: point2?.z ?? 0 - (point1?.z ?? 0),
    };

    return vector;
  }

  // 벡터의 크기를 계산하는 함수
  private calculateMagnitude(vector: Coordinate) {
    return Math.sqrt(vector.x ** 2 + vector.y ** 2 + (vector?.z ? vector.z ** 2 : 0));
  }

  // 벡터 간의 내적을 계산하는 함수
  private calculateDot(a: Coordinate, b: Coordinate) {
    return a.x * b.x + a.y * b.y + (a?.z ?? 0) * (b?.z ?? 0);
  }
}

export default new Calc();
