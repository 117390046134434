import type { UISystemManager } from "@/application/ui-system/view-model/model";
import { injectable } from "inversify";
import { BehaviorSubject, Subject, Observable, mergeMap } from "rxjs";
import { OneTimePaymentFailResponse, OneTimePaymentSuccessResponse } from "@view-data/Payment";
import { PaymentOneTimeResultViewModel } from "./model/PaymentOneTimeResultViewModel";
import type { RejectPaymentApproval } from "@/domain/usecase/invoice/model/RejectPaymentApproval";
import type { NotifyPaymentErrorMessage } from "@/domain/usecase/invoice/model/NotifyPaymentErrorMessage";
import type { RequestPaymentApproval } from "@/domain/usecase/invoice/model/RequestPaymentApproval";

@injectable()
export default class PaymentOnetimeResultViewModelImpl implements PaymentOneTimeResultViewModel {
  data: PaymentOneTimeResultViewModel["data"] = {};
  output: PaymentOneTimeResultViewModel["output"] = {
    // orderData: new BehaviorSubject<OrderData | null>(null),
  };
  route: PaymentOneTimeResultViewModel["route"] = {};

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucRequestPlanPaymentApproval: RequestPaymentApproval,
    private readonly ucRejectPaymentApproval: RejectPaymentApproval,
    private readonly ucNotifyPaymentError: NotifyPaymentErrorMessage,
  ) {
    this.init();
  }

  private init = () => {
    // this.output.orderData.next({
    //   id: "123456",
    //   status: "READY", // 결제 상태
    //   clientKey: this.clientKey, // NOTE
    //   paymentType: "ONE_TIME", // 결제 타입 (추가 정의 필요)
    //   customer: {
    //     key: "cust_001", // 고유 고객 키
    //     name: "홍길동", // 고객 이름
    //     email: "honggildong@example.com", // 고객 이메일
    //     phoneNumber: "01012345678", // 고객 전화번호
    //   },
    //   amount: {
    //     currency: "KRW", // 통화
    //     value: 50000, // 금액
    //   },
    //   orderName: "Face Fit Basic", // 주문 이름
    //   requestedAt: new Date("2024-01-01T10:00:00Z"), // 주문 요청 시간
    //   approvedAt: null, // 결제 승인 시간 (승인되지 않은 경우 null)
    // });
  };

  event: PaymentOneTimeResultViewModel["event"] = {
    onSuccessOneTimePayment: (response: OneTimePaymentSuccessResponse) => {
      console.log("APPROVE!!!!");
      const taxDetail = this.getTaxDetail(Number(response.amount));
      this.ucRequestPlanPaymentApproval
        .execute({
          invoiceKey: response.orderId,
          paymentInfo: {
            paymentKey: response.paymentKey,
            paymentGateType: "toss_payments",
            paymentMethod: response.paymentType,
            paymentAmount: {
              total: Number(response.amount),
              currency: "KRW",
            },
          },
        })
        .subscribe({
          next: () => {},
          error: () => {
            this.ucNotifyPaymentError.execute({
              errorType: "ONE_TIME_APPROVAL",
              invoiceKey: response.orderId,
              paymentInfo: {
                paymentKey: response.paymentKey,
                paymentGateType: "toss_payments",
                paymentMethod: response.paymentType,
                paymentAmount: {
                  tax: taxDetail.tax,
                  value: taxDetail.value,
                  currency: "KRW",
                },
              },
              errorMessage: null,
              errorCode: null
            });
          },
        });
    },
    onFailOneTimePayment: (response: OneTimePaymentFailResponse) => {
      this.ucRejectPaymentApproval
        .execute({
          invoiceKey: response.orderId,
          paymentGateType: "toss_payments",
          errorCode: response.code,
          errorMessage: response.message,
        })
        .subscribe({
          next: () => {},
          error: () => {
            this.ucNotifyPaymentError.execute({
              errorType: "ONE_TIME_REJECT",
              invoiceKey: response.orderId,
              paymentInfo: {
                paymentKey: null,
                paymentGateType: "toss_payments",
                paymentMethod: null,
                paymentAmount: null,
              },
              errorMessage: response.message,
              errorCode: response.code
            });
          },
        });
    },
  };

  private getTaxDetail = (amount: number) => {
    const tax = Math.round(amount * 0.1);

    return {
      tax,
      value: amount - tax,
    };
  };
}
