import { injectable } from "inversify";
import { FaceFitCustomerRegistrationViewModel, Query } from "./model/FaceFitCustomerRegistrationViewModel";
import { from, mergeMap, of, Subject, zip } from "rxjs";
import * as Entity from "@/domain/entity";
import { UI } from "@/application/view-data";
import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { CreateConsultingNote } from "@/domain/usecase/consulting/model/CreateConsultingNote";
import type { UploadConsultingPhoto } from "@/domain/usecase/consulting/model/UploadConsultingPhoto";
import type { UpdateConsultingNote } from "@/domain/usecase/consulting/model/UpdateConsultingNote";
import type { GetConsultingNote } from "@/domain/usecase/consulting/model/GetConsultingNote";
import { createObjectURL } from "blueimp-load-image";
import { Consulting } from "@/application/view-data";
import { v4 } from "uuid";

@injectable()
export class FaceFitCustomerRegistrationViewModelImpl implements FaceFitCustomerRegistrationViewModel {
  data: FaceFitCustomerRegistrationViewModel["data"] = {
    note: { customerName: "", customerPhoneNumber: "", photos: [], subjectCodes: [], consultantName: null },
    photos: [
      { type: "FRONTAL", originUrl: null, resizedUrl: null, ordinal: 1 },
      { type: "LEFT45", originUrl: null, resizedUrl: null, ordinal: 2 },
      { type: "LEFT90", originUrl: null, resizedUrl: null, ordinal: 3 },
      { type: "RIGHT45", originUrl: null, resizedUrl: null, ordinal: 4 },
      { type: "RIGHT90", originUrl: null, resizedUrl: null, ordinal: 5 },
    ],
    orderConsultingPhotoTypes: ["LEFT90", "LEFT45", "FRONTAL", "RIGHT45", "RIGHT90"],
    subjectParts: [
      { data: "EYES", isSelected: false },
      { data: "NOSE", isSelected: false },
      { data: "FACIAL_CONTOURING", isSelected: false },
    ],
  };

  output: FaceFitCustomerRegistrationViewModel["output"] = {
    note: new Subject<Consulting.Note>(),
    photos: new Subject<Consulting.Photo[]>(),
    subjectParts: new Subject<UI.SelectableItem<Entity.Type.SUBJECT_CODE>[]>(),
    customerRegistration: new Subject<Consulting.Note>(),
    openRegistrationConfirmModal: new Subject<boolean>(),
  };

  route: FaceFitCustomerRegistrationViewModel["route"] = {
    toNoteId: new Subject<{ noteId: number; query: Query }>(),
    toBack: new Subject<void>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucCreateConsultingNote: CreateConsultingNote,
    private readonly ucUpdateConsultingNote: UpdateConsultingNote,
    private readonly ucUploadConsultingPhoto: UploadConsultingPhoto,
    private readonly getConsultingNote: GetConsultingNote,
  ) {}

  input: FaceFitCustomerRegistrationViewModel["input"] = {
    clickSubjectPart: (selectedSubjectPart) => {
      if (this.data.subjectParts.some((subjectPart) => subjectPart.data === selectedSubjectPart && subjectPart.isSelected)) {
        this.data.subjectParts = this.data.subjectParts.map((subjectPart) => {
          subjectPart.isSelected = false;
          return subjectPart;
        });
      } else {
        this.data.subjectParts = this.data.subjectParts.map((subjectPart) => {
          if (subjectPart.data === selectedSubjectPart) {
            subjectPart.isSelected = true;
            return subjectPart;
          } else {
            subjectPart.isSelected = false;
            return subjectPart;
          }
        });
      }

      this.output.subjectParts.next([...this.data.subjectParts]);
    },
    changeCustomerName: (customerName: string) => {
      this.data.note.customerName = customerName;
      this.output.note.next({ ...this.data.note });
    },
    changeUploadInitialPhotos: (files: File[], query) => {
      const types: Entity.Type.CONSULTING_PHOTO_TYPE[] = ["FRONTAL", "LEFT90", "LEFT45", "RIGHT45", "RIGHT90"];

      const photos: Consulting.Photo[] = types.map((photoType, i) => {
        const tempId = v4();
        const file = files[i] || null;
        const objURL = file ? createObjectURL(file) : null;
        return {
          tempId,
          type: photoType,
          ordinal: i + 1,
          originUrl: null,
          resizedUrl: null,
          objURL: objURL ? objURL : undefined,
          file,
        };
      });
      const orderedPhotos = photos.sort((a, b) => {
        const order = ["LEFT90", "LEFT45", "FRONTAL", "RIGHT45", "RIGHT90"];
        return order.indexOf(a.type) - order.indexOf(b.type);
      });

      this.data.note.photos = orderedPhotos;
      this.data.photos = orderedPhotos;

      this.uiSystem.loadingHandler.backdropLoading.next(true);
      this.uploadPhotosToGCS(orderedPhotos, () => {
        const uploadedPhotos = this.data.note.photos?.filter((photo) => photo.originUrl && photo.resizedUrl);
        if (this.data.note.id) {
          const sub = this.ucUpdateConsultingNote
            .execute(this.data.note.id, { ...this.data.note, photos: uploadedPhotos })
            .pipe(
              mergeMap(({ consultingNoteId }) => {
                return this.getConsultingNote.execute({ noteId: consultingNoteId });
              }),
            )
            .subscribe({
              next: (note) => {
                this.data.note.noteNumber = note.noteNumber;
                this.route.toNoteId.next({ noteId: note.id, query });
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
              error: () => {
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
            });
        } else {
          const sub = this.ucCreateConsultingNote
            .execute({ ...this.data.note, photos: uploadedPhotos })
            .pipe(
              mergeMap(({ consultingNoteId }) => {
                return this.getConsultingNote.execute({ noteId: consultingNoteId });
              }),
            )
            .subscribe({
              next: (note) => {
                this.data.note.id = note.id;
                this.data.note.consultantName = note.consultantName;
                this.data.note.createdAt = note.createdAt;
                this.data.note.noteNumber = note.noteNumber;
                this.output.note.next(this.data.note);
                this.route.toNoteId.next({ noteId: note.id, query });
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
              error: () => {
                this.uiSystem.loadingHandler.backdropLoading.next(false);
                sub.unsubscribe();
              },
            });
        }
      });
    },
    changeDragAndDropPhotos: (photos) => {
      this.data.photos = photos;
      this.output.photos.next(this.data.photos);
    },
    clickUploadPhoto: (file: File, type: Entity.Type.CONSULTING_PHOTO_TYPE, index: number) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      const sub = this.ucUploadConsultingPhoto.execute({ file, ordinal: index }).subscribe({
        next: (result) => {
          this.data.photos = this.data.photos.map((photo) => {
            if (photo.type === type) {
              photo.tempId = v4();
              photo.resizedUrl = result.resizedUrl;
              photo.originUrl = result.originUrl;
              photo.ordinal = result.ordinal ?? index;
              return photo;
            } else {
              return photo;
            }
          });

          this.output.photos.next([...this.data.photos]);
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
    },
    clickDeletePhoto: (tempId: string) => {
      this.data.photos = this.data.photos.map((photo) => {
        if (photo.tempId === tempId) {
          photo = {
            tempId,
            type: photo.type,
            ordinal: photo.ordinal,
            originUrl: null,
            resizedUrl: null,
          };
          return photo;
        } else {
          return photo;
        }
      });
      this.output.photos.next([...this.data.photos]);
    },
    clickRegisterCustomer: (query, isMobile, hospitalSurgeryParts) => {
      if (!isMobile) {
        this.data.photos = this.data.photos.map((photo, idx) => {
          photo.type = this.data.orderConsultingPhotoTypes[idx];
          return photo;
        });
      }

      if (!this.data.photos.some((photo) => photo.type === "FRONTAL" && photo.originUrl)) {
        return this.uiSystem.toastHandler.toast.next({
          message: "정면사진을 등록해주세요.",
          type: "WARNING",
          position: { vertical: "top", horizontal: "center" },
        });
      }

      if (!Boolean(this.data.note.customerName && this.data.note.customerName.length > 0)) {
        return this.uiSystem.toastHandler.toast.next({
          message: "고객이름을 입력해주세요.",
          type: "WARNING",
          position: { vertical: "top", horizontal: "center" },
        });
      }

      if (Boolean(this.data.subjectParts.every((subjectPart) => !subjectPart.isSelected))) {
        return this.uiSystem.toastHandler.toast.next({
          message: "상담부위를 하나 선택해주세요.",
          type: "WARNING",
          position: { vertical: "top", horizontal: "center" },
        });
      }

      if (this.data.note.id) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        const photos = this.data.photos.filter((photo) => photo.originUrl);
        const subjectPart = this.data.subjectParts.find((subjectPart) => subjectPart.isSelected);

        this.data.note.photos = photos;
        if (subjectPart?.data) {
          this.data.note.subjectCodes = [subjectPart.data];
        }
        const hospitalCategories = hospitalSurgeryParts
          ?.filter((category) => this.data.note.subjectCodes?.some((code) => code === category.subjectCode))
          .map((category) => category.id);

        this.output.note.next({ ...this.data.note });

        const sub = this.ucUpdateConsultingNote
          .execute(this.data.note.id, { ...this.data.note, photos: photos, hospitalCategories: hospitalCategories })
          .subscribe({
            next: () => {
              this.output.openRegistrationConfirmModal.next(true);
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: () => {
              this.uiSystem.toastHandler.toast.next({
                message: "고객등록을 실패하였습니다.",
                type: "WARNING",
                position: { vertical: "top", horizontal: "center" },
              });
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      } else {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        this.data.note.photos = this.data.photos;
        const uploadedPhotos = this.data.note.photos?.filter((photo) => photo.originUrl && photo.resizedUrl);
        const sub = this.ucCreateConsultingNote
          .execute({ ...this.data.note, photos: uploadedPhotos })
          .pipe(
            mergeMap(({ consultingNoteId }) => {
              return this.getConsultingNote.execute({ noteId: consultingNoteId });
            }),
          )
          .subscribe({
            next: (note) => {
              this.data.note.id = note.id;
              this.data.note.noteNumber = note.noteNumber;
              this.data.note.consultantName = note.consultantName;
              this.data.note.createdAt = note.createdAt;
              const photos = this.data.photos.filter((photo) => photo.originUrl);
              const subjectPart = this.data.subjectParts.find((subjectPart) => subjectPart.isSelected);
              this.data.note.photos = photos;
              if (subjectPart?.data) {
                this.data.note.subjectCodes = [subjectPart.data];
              }
              this.output.note.next({ ...this.data.note });
              this.output.openRegistrationConfirmModal.next(true);
              this.route.toNoteId.next({ noteId: note.id, query });
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
            error: (err) => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              sub.unsubscribe();
            },
          });
      }
    },
    clickClose: () => {
      this.data.note = { customerName: "", customerPhoneNumber: "", photos: [], subjectCodes: [], consultantName: null };
      this.data.photos = [];
      this.data.subjectParts = [
        { data: "EYES", isSelected: false },
        { data: "NOSE", isSelected: false },
        { data: "FACIAL_CONTOURING", isSelected: false },
      ];
      this.output.note.next(this.data.note);
      this.output.photos.next(this.data.photos);
      this.output.subjectParts.next(this.data.subjectParts);
    },

    clickBack: () => {
      this.data.note = { customerName: "", customerPhoneNumber: "", photos: [], subjectCodes: [], consultantName: null };
      this.data.photos = [];
      this.data.subjectParts = [
        { data: "EYES", isSelected: false },
        { data: "NOSE", isSelected: false },
        { data: "FACIAL_CONTOURING", isSelected: false },
      ];
      this.output.note.next(this.data.note);
      this.output.photos.next(this.data.photos);
      this.output.subjectParts.next(this.data.subjectParts);
      this.route.toBack.next();
    },
  };

  event: FaceFitCustomerRegistrationViewModel["event"] = {
    onUpdateNote: (note) => {
      this.data.note = note;
      this.output.note.next({ ...this.data.note });
    },
  };

  private uploadPhotosToGCS = (photos: Consulting.Photo[], completion: () => void) => {
    const photosToUpload = photos.filter((photo) => photo.file);
    let completedUploads = 0;

    from(photosToUpload)
      .pipe(
        mergeMap((photo) =>
          zip(this.ucUploadConsultingPhoto.execute({ file: photo.file!, ordinal: photo.ordinal ?? 0 }), of(photo.type), of(photo.file)),
        ),
      )
      .subscribe({
        next: ([result, type, file]) => {
          completedUploads += 1;

          if (this.data.note.photos) {
            this.data.note.photos = this.data.note.photos.map((photo) =>
              photo.type === type
                ? { ...photo, ordinal: result.ordinal ?? 0, resizedUrl: result.resizedUrl, originUrl: result.originUrl }
                : photo,
            );
          }

          this.data.photos = this.data.photos.map((photo) => {
            if (photo.type === type) {
              photo.originUrl = result.originUrl;
              photo.resizedUrl = result.resizedUrl;
              photo.ordinal = result.ordinal ?? 0;
              return photo;
            } else {
              return photo;
              1;
            }
          });

          if (completedUploads === photosToUpload.length) {
            this.output.photos.next([...this.data.photos]);
            completion();
          }
        },
        error: (err) => {
          this.uiSystem.loadingHandler.backdropLoading.next(false);
        },
      });
  };
}
