import { inject, injectable } from "inversify";
import { RejectPaymentApproval, RejectPaymentApprovalInput, RejectPaymentApprovalOutput } from "./model/RejectPaymentApproval";

import { Observable, of } from "rxjs";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import { NotifyPaymentErrorMessage, NotifyPaymentErrorMessageInput } from "./model/NotifyPaymentErrorMessage";
import { WEBHOOK_TYPES } from "@/data/web-hook/index.container.types";
import type { SlackWebHook } from "@/data/web-hook/model/SlackWebHook";

@injectable()
export class NotifyPaymentErrorMessageImpl implements NotifyPaymentErrorMessage {
  constructor(@inject(WEBHOOK_TYPES.SlackWebHook) private readonly slackWebHook: SlackWebHook) {}

  execute(input: NotifyPaymentErrorMessageInput): Observable<void> {
    const channelPath =
      process.env.ENVIRONMENT === "prd" ? "/B07RUDSU79P/qmqPBhAwJgmlkr1Y1Ar2HRZQ" : "/B05U4J6M140/7zBQ9xgYnKhGF9o2IZL0i9qD";

    return this.slackWebHook.notifySlackChannel({
      channelPath: channelPath,
      slackMessage: {
        blocks: [
          {
            type: "header",
            text: {
              type: "plain_text",
              text: this.getMessageTitleByType(input.errorType),
            },
          },
          {
            type: "divider",
          },
          {
            type: "section",
            text: {
              type: "mrkdwn",
              text: `*결제 정보*\n• invoiceKey: ${input.invoiceKey}\n• paymentKey: ${
                input.paymentInfo.paymentKey ?? "-"
              }\n• paymentMethod: ${input.paymentInfo.paymentMethod ?? "-"}\n`,
            },
          },
        ],
      },
    });
  }

  private getMessageTitleByType = (errorType: NotifyPaymentErrorMessageInput["errorType"]) => {
    switch (errorType) {
      case "ONE_TIME_APPROVAL":
        return "FaceFit Biz 단건 결제 승인 에러";
      case "ONE_TIME_REJECT":
        return "FaceFit Biz 단건 결제 실패 에러";
    }
  };
}
