import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import Calc from "../utils/Calc";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class NoseTipShape extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    noseRight: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
    noseLeft: {
      right: {
        top: Coordinate;
        bottom: Coordinate;
      };
      left: {
        top: Coordinate;
        bottom: Coordinate;
      };
    };
  };

  ratio: {
    right: number;
    center: number;
    left: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawGuideLines();
    await this.delay(300);
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(500);
    this.photoBaseDrawer.drawRatioText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawGuideLines();
    await this.delay(300);
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(500);
    this.bestRatioDrawer.drawRatioText();
  };

  private photoBaseDrawer = {
    drawNoseLines: async () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.right.top,
        point2: this.coordinate.noseRight.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.left.top,
        point2: this.coordinate.noseRight.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.right.top,
        point2: this.coordinate.noseLeft.right.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.left.top,
        point2: this.coordinate.noseLeft.left.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      // this.drawLineBetweenPoints({
      //   point1: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[2].y },
      //   point2: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[4].y },
      //   lineStyle: PhotoBaseLine,
      //   isDrawEndPoint: true,
      // });
      // await this.delay(300);
      // const keyPoints = [219, 218, 237, 125, 354, 457, 438, 439];
      // const lineCoordinates: Coordinate[] = [];
      // const sideLineCoordinates: Coordinate[] = [];

      // keyPoints.forEach((keyPoint) => {
      //   lineCoordinates.push(this.faceCoordinates[keyPoint]);
      //   if (keyPoint < 248) {
      //     sideLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
      //   }
      // });

      // this.frontalFaceCanvasDrawer.drawLineByCoordinates(lineCoordinates, { ...PhotoBaseLine, lineColor: "#F900FE" }, true);
      // this.drawLineByCoordinates(sideLineCoordinates, { ...PhotoBaseLine, lineColor: "#F900FE" }, true);
    },

    drawGuideLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: { ...this.coordinate.noseRight.right.top, y: this.coordinate.noseRight.right.bottom.y - 20 },
        point2: { ...this.coordinate.noseLeft.left.top, y: this.coordinate.noseLeft.left.bottom.y - 20 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });
    },

    drawRatioText: () => {
      const textStyle = { x: 0, y: 25, fontSize: 18, color: "#FF9900" };

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${Calc.pFloat(this.ratio.right * 10)}`,
        this.coordinate.noseRight.right.bottom,
        this.coordinate.noseRight.left.bottom,
        textStyle,
      );
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${Calc.pFloat(this.ratio.center * 10)}`,
        this.coordinate.noseRight.left.bottom,
        this.coordinate.noseLeft.right.bottom,
        textStyle,
      );

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `${Calc.pFloat(this.ratio.left * 10)}`,
        this.coordinate.noseLeft.right.bottom,
        this.coordinate.noseLeft.left.bottom,
        textStyle,
      );
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: async () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.right.top,
        point2: this.coordinate.noseRight.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseRight.left.top,
        point2: this.coordinate.noseRight.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.right.top,
        point2: this.coordinate.noseLeft.right.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.coordinate.noseLeft.left.top,
        point2: this.coordinate.noseLeft.left.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      // this.drawLineBetweenPoints({
      //   point1: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[2].y },
      //   point2: { ...this.sideFaceCoordinate[129], y: this.sideFaceCoordinate[4].y },
      //   lineStyle: BestRatioLineStyle,
      //   isDrawEndPoint: true,
      // });

      await this.delay(300);

      const keyPoints = [219, 218, 237, 125, 354, 457, 438, 439];
      const lineCoordinates: Coordinate[] = [];
      const sideLineCoordinates: Coordinate[] = [];

      keyPoints.forEach((keyPoint) => {
        lineCoordinates.push(this.faceCoordinates[keyPoint]);
        if (keyPoint < 248) {
          sideLineCoordinates.push(this.sideFaceCoordinate[keyPoint]);
        }
      });

      this.frontalFaceCanvasDrawer.drawLineByCoordinates(lineCoordinates, { ...BestRatioLineStyle, lineColor: "#FF678C" }, true);
      // this.drawLineByCoordinates(sideLineCoordinates, BestRatioLineStyle, true);
    },

    drawGuideLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: { ...this.coordinate.noseRight.right.top, y: this.coordinate.noseRight.right.bottom.y - 20 },
        point2: { ...this.coordinate.noseLeft.left.top, y: this.coordinate.noseLeft.left.bottom.y - 20 },
        lineStyle: DotLineStyle,
        isDrawEndPoint: false,
      });

      // this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
      //   point1: this.coordinate.noseRight.right.top,
      //   point2: this.coordinate.noseLeft.left.top,
      //   lineStyle: DotLineStyle,
      //   isDrawEndPoint: false,
      // });
    },

    drawRatioText: () => {
      const textStyle = { x: 0, y: 50, fontSize: 18, color: "#00D7CA" };

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `2.0`,
        this.coordinate.noseRight.right.bottom,
        this.coordinate.noseRight.left.bottom,
        textStyle,
      );
      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `6.0`,
        this.coordinate.noseRight.left.bottom,
        this.coordinate.noseLeft.right.bottom,
        textStyle,
      );

      this.frontalFaceCanvasDrawer.drawTextBetweenPoints(
        `2.0`,
        this.coordinate.noseLeft.right.bottom,
        this.coordinate.noseLeft.left.bottom,
        textStyle,
      );
    },
  };

  private getCoordinate = () => {
    const topYPoint = this.faceCoordinates[197].y;
    const middleYPoint = this.faceCoordinates[4].y;
    const bottomYPoint = this.faceCoordinates[2].y + 20;
    return {
      noseRight: {
        right: {
          top: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: topYPoint },
          bottom: { ...this.faceCoordinates[129], x: this.faceCoordinates[129].x + 6, y: bottomYPoint },
        },
        left: {
          top: { ...this.faceCoordinates[220], y: topYPoint },
          bottom: { ...this.faceCoordinates[220], y: bottomYPoint },
        },
      },
      noseLeft: {
        right: {
          top: { ...this.faceCoordinates[440], y: topYPoint },
          bottom: { ...this.faceCoordinates[440], y: bottomYPoint },
        },
        left: {
          top: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: topYPoint },
          bottom: { ...this.faceCoordinates[358], x: this.faceCoordinates[358].x - 6, y: bottomYPoint },
        },
      },
    };
  };

  private calcRatio = () => {
    const rightDistance = Calc.calculateDistance(this.coordinate.noseRight.right.bottom, this.coordinate.noseRight.left.bottom, "2d");
    const centerDistance = Calc.calculateDistance(this.coordinate.noseRight.left.bottom, this.coordinate.noseLeft.right.bottom, "2d");
    const leftDistance = Calc.calculateDistance(this.coordinate.noseLeft.right.bottom, this.coordinate.noseLeft.left.bottom, "2d");

    const totalDistance = rightDistance + centerDistance + leftDistance;

    return {
      right: Calc.calcRatio(totalDistance, rightDistance),
      center: Calc.calcRatio(totalDistance, centerDistance),
      left: Calc.calcRatio(totalDistance, leftDistance),
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
