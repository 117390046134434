import { DrawingFacialLandmarks } from "@view-model/face-classification/face/DrawingFacialLandmarks";
import { Config, Coordinate } from "../../index.interface";
import Calc from "../../utils/Calc";
import { BestRatioLineStyle, PhotoBaseLine } from "@view-model/face-classification/utils/Styles";

export class ForeheadNoseAngle45 extends DrawingFacialLandmarks {
  private coordinate: {
    top: Coordinate;
    middle: Coordinate;
    bottom: Coordinate;
  };

  ratio: {
    angle: number;
  };

  constructor({ config }: { config: Config }) {
    super(config);
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async (currentForeheadNoseAngle: { angle: number } | null) => {
    this.photoBaseDrawer.drawNoseLines();
    await this.delay(300);
    if (currentForeheadNoseAngle) {
      this.photoBaseDrawer.drawRatioText(currentForeheadNoseAngle);
    }
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    await this.delay(300);
    this.bestRatioDrawer.drawRatioText({ angle: 148 });
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      this.drawLineByCoordinates([this.faceCoordinates[9], this.faceCoordinates[168], this.faceCoordinates[5]], PhotoBaseLine, true);
      this.drawArcBetweenPoints({
        center: this.faceCoordinates[168],
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[5],
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },
    drawRatioText: (currentForeheadNoseAngle: { angle: number }) => {
      const textStyle = { x: 101, y: 0, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${currentForeheadNoseAngle.angle}°`, this.faceCoordinates[9], this.faceCoordinates[168], textStyle);
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineByCoordinates([this.faceCoordinates[9], this.faceCoordinates[168], this.faceCoordinates[5]], BestRatioLineStyle, true);
      this.drawArcBetweenPoints({
        center: this.faceCoordinates[168],
        point1: this.faceCoordinates[9],
        point2: this.faceCoordinates[5],
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },
    drawRatioText: (currentForeheadNoseAngle: { angle: number }) => {
      const textStyle = { x: 93, y: 25, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${currentForeheadNoseAngle.angle}°`, this.faceCoordinates[9], this.faceCoordinates[168], textStyle);
    },
  };

  private getCoordinate = () => {
    const offsetX = 30;

    return {
      top: { ...this.faceCoordinates[9], x: this.faceCoordinates[9].x + offsetX },
      middle: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x + offsetX },
      bottom: { ...this.faceCoordinates[5], x: this.faceCoordinates[5].x + offsetX },
    };
  };

  private calcRatio = () => {
    const angle = Calc.calcAngle(
      { point1: this.faceCoordinates[9], center: this.faceCoordinates[168], point2: this.faceCoordinates[5] },
      "2d",
    );
    return {
      angle,
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
