import { injectable, inject } from "inversify";
import { map, Observable } from "rxjs";
import { DeleteConsultingNotes, DeleteConsultingNotesInput, DeleteConsultingNotesOutput } from "./model/DeleteConsultingNotes";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";

@injectable()
export class DeleteConsultingNotesImpl implements DeleteConsultingNotes {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: DeleteConsultingNotesInput): Observable<void> {
    return this.operationConsultingRepository.deleteConsultingNotes(input.noteIds);
  }
}
